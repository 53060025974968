
import {defineComponent, inject, onMounted, PropType, ref} from 'vue';
import {DocumentSearchRequestDto, PaginationDto} from "@dex/squeeze-client-ts";
import {FreezeSearchMask, FreezeSearchMaskField} from "@/apis/freeze/Types";
import RecordFields from "@/apps/freeze/components/RecordFields.vue";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {useRoute} from "vue-router";
import RecordViewer from "@/apps/freeze/components/RecordViewer.vue";
import Dialog from "primevue/dialog";
import router from "@/router";
import {EasClientKey, FreezeClientKey} from "@/apps/freeze/DI";
import Toolbar from "primevue/toolbar";

export default defineComponent({
	name: 'RecordView',
	components: {
		Toolbar,
		RecordFields,
		Splitter,
		SplitterPanel,
		Dropdown,
		RecordViewer,
		Dialog,
	},
	props: {
		storeId: String,
		recordId: String,
		searchMaskId: String,
		searchRequest: {
			type: Object as PropType<DocumentSearchRequestDto>,
			default: {},
		},
		pagination: {
			type: Object as PropType<PaginationDto>,
			default: {},
		},
		tableSortStart: Array,
	},

	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** list of all records */
		const records = ref<any[]>([]);

		/** Is the table currently loading */
		const loadingHitList = ref(false);
		const loadingRecord = ref(false);

		/** List of all Document-Class-Fields */
		const searchFields = ref<FreezeSearchMaskField[]>([]);

		/** Current record */
		const record = ref<any>({});

		/** Api for Sqeeuze-Requests */
		const freezeApi = inject(FreezeClientKey)!
		const easApi = inject(EasClientKey)!

		const selectedDocument = ref<any>({});

		/** List of all Stores */
		const searchMasks = ref<any[]>([]);

		/** Should the Metadata be shown? */
		const showMetaData = ref(false);

		/** Currently selected Store */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		/** Downloads a records attachment and returns
		 * @param store
		 * @param recordId
		 */
		const getRecord = async (store: string, recordId: string) => {
			try {
				loadingRecord.value = true;
				const recordRet = await easApi.getRecord(store, recordId);
				record.value = recordRet;
				loadingRecord.value = false;
				record.value.attachmentsLoading = true;
				record.value.attachments = await easApi.getRecordAttachments(props.storeId!, props.recordId!);

				//record.value.attachments.forEach((attachment: any) => record.value.url = `${ClientManager.getInstance().freezeUrl}/eas/archives/${props.storeId}/record/${record.value.id}/attachment/${attachment.id}`)

				for (const attachment of record.value.attachments) {
					const documentBase64 = await easApi.getAttachment(props.storeId!, props.recordId!, attachment.id!);
					attachment.documentBase64 = documentBase64;
				}

				if (record.value.attachments && record.value.attachments[0]) {
					const firstAttachment = record.value.attachments[0];
					record.value.currentDocumentBase64 = firstAttachment.documentBase64;
					record.value.currentDocumentName = firstAttachment.name;

					selectedDocument.value = firstAttachment;
				}
			} catch (e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			} finally {
				loadingRecord.value = false;
				record.value.attachmentsLoading = false;
			}
		}

		/**
		 * Get all Search Masks
		 */
		const getAllSearchMasks = async () => {
			try {
				loadingHitList.value = true;
				const data = await freezeApi.getAllSearchMasks();
				searchMasks.value = data;

				if (route.query.hitList) {
					const queryMask = searchMasks.value.find(mask => mask.name === route.query.hitList);
					if (queryMask) {
						selectedMask.value = queryMask;
						searchFields.value = queryMask.fields;
					} else {
						// first of all available hit lists is used as the hit list
						selectedMask.value = searchMasks.value[0];
					}
				} else {
					// first of all available hit lists is used as the hit list when query not exists
					selectedMask.value = searchMasks.value[0];
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			} finally {
				loadingHitList.value = false;
			}
		}

		onMounted(() => {
			getRecord(props.storeId!, props.recordId!);
			getAllSearchMasks();
		});

		/** set the selected search mask */
		const selectSearchMask = (event: { value: FreezeSearchMask }) => {
			if (!event.value) {
				searchFields.value = [];
				router.replace({ path: route.path, query: { hitList: undefined }})
				return;
			}
			selectedMask.value = event.value;
			searchFields.value = selectedMask.value.fields;

			router.replace({ path: route.path, query: { hitList: selectedMask.value.name }})
		}

		/**
		 * Opens a document in wider view
		 * @param currentDocument
		 */
		const openDocument = (currentDocument: any) => {
			selectedDocument.value = currentDocument;
			if (!selectedDocument.value.name.toLowerCase().includes(".pdf") &&
				!selectedDocument.value.name.toLowerCase().includes(".png") &&
				!selectedDocument.value.name.toLowerCase().includes(".jpg") &&
				!selectedDocument.value.name.toLowerCase().includes(".jpeg")
			) {
				return;
			}

			record.value.currentDocumentBase64 = selectedDocument.value.documentBase64;
			record.value.currentDocumentName = selectedDocument.value.name;
		}

		/** Change the View to the Overview */
		const goBackToRecordList = () => {
			router.replace({
				name: 'FreezeRecords',
				params: {
					storeId: "" + props.storeId,
					searchMaskId: props.searchMaskId ? "" + props.searchMaskId : searchMasks.value[0].name,
					searchRequest: JSON.stringify(props.searchRequest),
					tableSortStart: JSON.stringify(props.tableSortStart),
					pagination: JSON.stringify(props.pagination),
				},
			});
		}

		/** Triggered if user wants to copy the metadata to clipboard */
		const onCopyMetadataToClipboard = () => {
			const labels = record.value.headerFields;
			if (labels && navigator.clipboard) {
				const text = Object
					.keys(labels)
					.map(key => {
						const translatedKey = t(`Freeze.Eas.HeaderFields.${key}`);
						return `${translatedKey}: ${labels[key]}`;
					})
					.join("\n");

				navigator.clipboard.writeText(text)
					.then(() => {
						ToastManager.showSuccess(toast, t('Toasts.Title.Success'), t('Forms.Hints.CopyClipboardDone'));
					});
			}
		}

		return {
			records,
			searchFields,
			record,
			selectedDocument,
			searchMasks,
			selectedMask,
			loadingHitList,
			loadingRecord,
			showMetaData,
			selectSearchMask,
			openDocument,
			getRecord,
			showDropdownOverlay,
			goBackToRecordList,
			onCopyMetadataToClipboard,
		}
	},
});

