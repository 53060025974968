<template>
	<div class="p-component p-fluid p-formgrid p-grid p-p-2">
		<template v-if="searchMaskFields && searchMaskFields.length >0">
			<template v-for="field of searchMaskFields" v-bind:key="field.name">
				<div class="field p-col-12 p-md-6 p-mb-2">
					<div class="p-input-filled">
						<label :for="field.name">{{ field.desc }}</label>
						<InputText :readonly="true" :id="field.name" :value="getValueFromSearchMask(field.name)"/>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<template v-for="(value, name) in fields" v-bind:key="name">
				<div class="field p-col-12 p-md-6 p-mb-2">
					<div class="p-input-filled">
						<label :for="name">{{ name }}</label>
						<InputText  :readonly="true" :id="name" :value="value"/>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>
<script lang="ts">
import {
	defineComponent, PropType
} from 'vue';
import InputText from "primevue/inputtext";

export default defineComponent({
	name: 'RecordFields',
	components: {
		InputText,
	},
	props: {
		fields: Object as PropType<any>,
		searchMaskFields: Array as PropType<any[]>,
	},
	setup(props) {
		/**
		 * Gets the value for search mask field
		 * @param fieldName
		 */
		const getValueFromSearchMask = (fieldName: string) => {
			const fields = props.fields as any;
			if (fields[fieldName]) {
				return fields[fieldName] as string;
			}

			return "";
		}

		return {
			getValueFromSearchMask,
		};
	},
});

</script>
<style lang="scss" scoped>
.readonly {
	font-weight: bold;
	color: #2b2b2b;
	opacity: 0.5;
}

.validation-field {
	margin-top: 0.5rem;
}

.p-field.low-field {
	margin-bottom: 0.5rem;
}

.low-field ::v-deep(.p-autocomplete .p-button) {
	padding: 0.5rem;
	width: 2.5rem;
}

.validation-field ::v-deep(.p-fieldset-legend a) {
	padding: 1rem !important;
}

.validation-field ::v-deep(.p-autocomplete-input),
.validation-field ::v-deep(.p-button), {
	margin-top: 0.25rem;
}

.low-fieldset ::v-deep(.p-fieldset-content) {
	padding: 0 1rem 0 1rem !important;
}

input.p-inputtext.p-component.p-filled.amount-field{
	text-align: right;
}

::v-deep(input.p-autocomplete-input.p-inputtext.p-component.amount-field) {
	text-align: right;
}

.low-field > .p-input-filled > .p-inputtext {
	padding: 0.5rem 0.5rem !important;
}

.low-field ::v-deep(.p-autocomplete-input.p-inputtext) {
	padding: 0.5rem 0.5rem !important;
}

::v-deep(.p-badge) {
	min-width: 1rem;
	width: max-content;
	height: 1rem;
	line-height: 1rem;
	margin-left: 0.25rem;
	border-radius: 0;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown) {
	background: #4894ac4d;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown span.p-button-icon) {
	color: var(--primary-color);
}

::v-deep(.p-input-filled .p-inputtext.forceapproval.p-component) {
	background-color: #4894ac4d;
}

::v-deep(.p-input-filled .p-inputtext.p-invalid.p-component) {
	background-color: #ffcdd254;
}

</style>
