<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<!--div class="p-field p-col-12">
			<label for="batchClassId">{{$t('Squeeze.BatchClasses.BatchClass')}} *</label>
			<Dropdown id="batchClassId" v-model="value.batchClassId" :options="batchClasses" :loading="loading" :class="{'p-invalid':v$.batchClassId.$invalid && showErrorMessage}" optionValue="id" optionLabel="description" @change="update"  />
			<small v-if="v$.batchClassId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.BatchClasses.BatchClass')}) }}
			</small>
		</div-->
		<div class="p-field p-col-12">
			<label for="documentClassId">{{$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass')}}</label>
			<Dropdown id="documentClassId"
				v-model="value.documentClassId"
				:options="documentClasses"
				:loading="loading"
				optionValue="documentClassId"
				optionLabel="description"
				@change="update"
				:placeholder="$t('Squeeze.General.None')"
				:showClear="true"
			/>
		</div>
		<div v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" class="p-field p-col-6">
			<label for="server">{{$t('Squeeze.Imports.Server')}} *</label>
			<InputText id="server" v-model="value.server" :class="{'p-invalid':v$.server.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.server.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Server')}) }}
			</small>
		</div>
		<div v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" class="p-field p-col-6">
			<label for="port">{{$t('Squeeze.Imports.Port')}} *</label>
			<InputNumber id="port" v-model="value.port" :useGrouping="false" :class="{'p-invalid':v$.port.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.port.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Port')}) }}
			</small>
		</div>
		<div class="p-field" :class="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated' ? 'p-col-6' : 'p-col-12'">
			<label for="protocol">{{$t('Squeeze.Imports.Protocol')}}</label>
			<Dropdown id="protocol" v-model="value.protocol" :options="protocols" :loading="loading" :class="{'p-invalid':v$.protocol.$invalid && showErrorMessage}" optionValue="id" optionLabel="description" @change="onChangeProtocol($event)"  />
			<small v-if="v$.protocol.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Protocol')}) }}
			</small>
		</div>
		<div v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" class="p-field p-col-6">
			<label for="encryption">{{$t('Squeeze.Imports.Encryption')}}</label>
			<Dropdown id="encryption" v-model="value.encryption" :options="encryptions" :loading="loading" :class="{'p-invalid':v$.encryption.$invalid && showErrorMessage}" optionValue="id" optionLabel="description" @change="update"  />
			<small v-if="v$.encryption.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Encryption')}) }}
			</small>
		</div>
		<div v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" class="p-field p-col-12">
			<label for="checkCertificate">{{$t('Squeeze.Imports.CheckCert')}}</label>
			<Dropdown id="checkCertificate" v-model="value.checkCertificate" :options="checkCertificates" :loading="loading" :class="{'p-invalid':v$.checkCertificate.$invalid && showErrorMessage}" optionValue="id" optionLabel="name" @change="update"  />
			<small v-if="v$.checkCertificate.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.CheckCert')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" for="user">{{$t('Squeeze.Login.UserName')}} *</label>
			<label v-else for="user">{{$t('Squeeze.Imports.PostOfficeBox')}} *</label>
			<InputText id="user" v-model="value.user" :class="{'p-invalid':v$.user.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.user.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Login.UserName')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label v-if="value.protocol !== 'microsoft' && value.protocol !== 'microsoft_delegated'" for="password">{{$t('Squeeze.Login.Password')}} *</label>
			<label v-else for="password">Client Secret *</label>
			<Password id="password" v-model="value.password" :class="{'p-invalid':v$.password.$invalid && showErrorMessage}" @change="update" :feedback="false" toggleMask />
			<small v-if="v$.password.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Login.Password')}) }}
			</small>
		</div>

		<!-- Show inputs when protocol is microsoft graph api or microsoft graph api delegated -->
		<template v-if="value.protocol === 'microsoft' || value.protocol === 'microsoft_delegated'">
			<div class="p-field p-col-6">
				<label for="clientId">Client ID *</label>
				<InputText id="clientId" v-model="value.clientId" :class="{'p-invalid':v$.clientId.$invalid && showErrorMessage}" @change="update" />
				<small v-if="v$.clientId.$invalid && showErrorMessage" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: 'Client ID'}) }}
				</small>
			</div>
			<div class="p-field p-col-6">
				<label for="tenantId">Tenant ID *</label>
				<InputText id="tenantId" v-model="value.tenantId" :class="{'p-invalid':v$.tenantId.$invalid && showErrorMessage}" @change="update" />
				<small v-if="v$.tenantId.$invalid && showErrorMessage" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: 'Tenant ID'}) }}
				</small>
			</div>
		</template>

		<div class="p-field p-col-12">
			<label for="inboxFolder">{{$t('Squeeze.Imports.Inbox')}} *</label>
			<InputText id="inboxFolder" v-model="value.inboxFolder" :class="{'p-invalid':v$.inboxFolder.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.inboxFolder.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Inbox')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="validFolder">{{$t('Squeeze.Imports.Valid')}} *</label>
			<InputText id="validFolder" v-model="value.validFolder" :class="{'p-invalid':v$.validFolder.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.validFolder.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Valid')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="errorFolder">{{$t('Squeeze.General.Error')}} *</label>
			<InputText id="errorFolder" v-model="value.errorFolder" :class="{'p-invalid':v$.errorFolder.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.errorFolder.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.General.Error')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import InputNumber from '@/components/DexInputNumber.vue';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import {BatchClass, BatchClassClassification, MailImportConfig} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ProtocolEnum = MailImportConfig.ProtocolEnum;
import EncryptionEnum = MailImportConfig.EncryptionEnum;
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

interface MailImportMicrosoftConfig extends MailImportConfig {
	clientId?: string;
	tenantId?: string;
}

export default defineComponent({
	name: "MailForm",
	components: {
		InputText, InputNumber, Dropdown, Password,
	},
	props: {
		mail: {
			type: Object as PropType<MailImportMicrosoftConfig>,
			required: true,
		},
		batchClassId: {
			type: Number,
			default: 1,
			required: true,
		},
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: [],
			required: true,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Array with the Protocols */
		const protocols = computed(() => {
			return [
				{
					id: 'imap',
					description: 'IMAP',
				},
				{
					id: 'ews',
					description: 'EWS',
				},
				{
					id: 'microsoft',
					description: 'Microsoft Graph API',
				},
				{
					id: 'microsoft_delegated',
					description: 'Microsoft Graph API Delegated',
				},
				{
					id: 'inactive',
					description: t('Squeeze.Imports.Inactive'),
				},
			]
		});

		/** Array with the Encryptions */
		const encryptions = computed(() => {
			return [
				{
					id: 'ssl',
					description: 'SSL',
				},
				{
					id: 'tls',
					description: 'TLS',
				},
				{
					id: 'notls',
					description: t('Squeeze.General.None'),
				},
			]
		});

		/** List of checkCertificates booleans */
		const checkCertificates = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<MailImportMicrosoftConfig>({
			batchClassId: props.batchClassId,
			documentClassId: undefined,
			server: '',
			port: undefined,
			protocol: ProtocolEnum.Imap,
			encryption: EncryptionEnum.Ssl,
			checkCertificate: false,
			user: '',
			password: '',
			clientId: '0',
			tenantId: '0',
			inboxFolder: '',
			validFolder: '',
			errorFolder: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			//batchClassId: { required },
			server: { required },
			port: { required },
			protocol: { required },
			encryption: { required },
			checkCertificate: { required },
			user: { required },
			password: { required },
			clientId: { required },
			tenantId: { required },
			inboxFolder: { required },
			validFolder: { required },
			errorFolder: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.mail);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.mail, () => {
			Object.assign(value, props.mail);
		})

		/** Triggered on change of any field */
		const update = async () => {
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}

		/** Triggered on change of protocol dropdown field */
		const onChangeProtocol = (event: { originalEvent: PointerEvent; value: string }) => {
			if (event.value === 'inactive' && (value.clientId && value.clientId.length > 1) && (value.tenantId && value.tenantId.length > 1)) {
				// check if inactive protocol selected and clientId & tenantId filled
				value.user = value.user + '/' + value.clientId + '/' + value.tenantId;
			} else if ((event.value === 'microsoft' || event.value === 'microsoft_delegated') && value.user.includes('/')) {
				// check if microsoft or microsoft_delegated protocol selected and user include a slash
				const mailUser = value.user.split('/');
				value.user = mailUser[0];
				value.clientId = mailUser[1];
				value.tenantId = mailUser[2];
			} else if (event.value === 'microsoft' || event.value === 'microsoft_delegated') {
				// remove default value when microsoft or microsoft_delegated selected
				if (value.clientId === '0') {
					value.clientId = '';
				}
				if (value.tenantId === '0') {
					value.tenantId = '';
				}
			} else {
				// set default value
				value.clientId = '0';
				value.tenantId = '0';
			}

			update();
		}

		return {
			t,
			toast,
			loading,
			protocols,
			encryptions,
			checkCertificates,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
			onChangeProtocol,
		}
	},
});

</script>

<style scoped>

</style>
