<template>
	<div v-if="!isUploadShown" class="dragFiles" v-bind:class="{ drag: showDragDialog }" @dragenter="onDragEnter" @dragleave="onDragLeave" @drop="onDrop">
		<i class="mdi mdi-cloud-upload-outline uploadDragImage"></i>
	</div>
	<Splitter layout="vertical" :gutterSize="10" stateStorage="local" stateKey="squeeze.validation.verticalSplitterLayout" class="root-splitter" :class="activeTable && activeTable.hidden ? 'disabled-splitter-gutter' : ''"
		@mousedown="onSplitterMouseDown"
		@mouseup="onSplitterMouseUp"
		@resizeend="onSplitterResizeEnd"
	>
		<SplitterPanel class="p-d-flex" :size="80" :minSize="50">
			<Splitter :gutterSize="10" stateStorage="local" stateKey="squeeze.validation.horizontalSplitterLayout" @mousedown="onSplitterMouseDown" @mouseup="onSplitterMouseUp" @resizeend="onSplitterResizeEnd">
				<SplitterPanel class="p-d-flex" :size="33" :minSize="30">
					<div class="content">
						<Toolbar class="p-p-2">
							<template #left>
								<Button
									v-if="hideValidationButtons"
									class="p-shadow-4 p-button-md"
									v-tooltip="$t('Squeeze.General.Back')"
									:icon="!hideValidationButtons ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-left'"
									@click="!hideValidationButtons ? goBackToValidationList() : reopenValidationView()"
								/>
								<div v-if="!hideValidationButtons">
									<Button class="p-shadow-4" v-tooltip="$t('Squeeze.Validation.Buttons.BackToList')" icon="mdi mdi-chevron-double-up" @click="goBackToValidationList" :disabled="disableBrowseButtons"></Button>
									<Button class="p-shadow-4 p-ml-1" v-tooltip="$t('Squeeze.Validation.Buttons.PrevDocument')" icon="mdi mdi-chevron-left" @click="browseLeft(false)" :disabled="disableBrowseButtons || blockBrowseButtons"></Button>
									<Button class="p-shadow-4 p-ml-1" v-tooltip="$t('Squeeze.Validation.Buttons.NextDocument')" icon="mdi mdi-chevron-right" @click="goToNextDocument(false)" :disabled="disableBrowseButtons || blockBrowseButtons"></Button>
								</div>
							</template>
							<template #right>
								<div v-if="hideValidationButtons && showHeadTraining || showPositionTraining || showOcrResults || showLocatorTest">
									<Button class="p-shadow-4 p-button-md" type="button" icon="mdi mdi-dots-vertical" v-tooltip="$t('Squeeze.General.Options')" @click="toggleToolBarOptions" aria-haspopup="true" aria-controls="overlayMenu" :disabled="blocked.buttons.menu" />
									<Menu id="overlayMenu1" ref="menu" :model="toolbarOptions" :popup="true" style="border:none; max-height: 80vh; overflow-y: scroll;" />
								</div>
								<div v-if="!hideValidationButtons">
									<Button id="btnValidation" class="p-button-md p-mr-1" :class="blocked.buttons.save ? 'p-button-text' : 'p-shadow-4'" @click="validateAndExportDocument" :label="toolbarButtonText ? $t('Squeeze.Validation.Buttons.Validate') : null" icon="mdi mdi-check" style="background-color: var(--success-color)" :disabled="blocked.buttons.save" />
									<Button class="p-button-md p-mr-1" :class="blocked.buttons.suspend ? 'p-button-text' : 'p-shadow-4'" @click="showDialog('suspendDocument')" :label="toolbarButtonText ? $t('Squeeze.Validation.Buttons.Suspend') : null" icon="mdi mdi-pause" style="background-color: var(--dex-primary-color)" :disabled="blocked.buttons.suspend" />
									<Button class="p-button-md p-mr-1" :class="blocked.buttons.delete ? 'p-button-text' : 'p-shadow-4'" @click="showDialog('deleteDocument')" :label="toolbarButtonText ? $t('Squeeze.Validation.Buttons.Delete') : null" icon="mdi mdi-delete-outline" style="background-color: var(--error-color)" :disabled="blocked.buttons.delete" />
									<Button class="p-shadow-4 p-button-md p-mr-1" type="button" icon="mdi mdi-dots-vertical" v-tooltip="$t('Squeeze.General.Actions')" @click="toggleToolBarOptions" aria-haspopup="true" aria-controls="overlayMenu" :disabled="blocked.buttons.menu" />
									<Menu id="overlayMenu" ref="menu" :model="toolbarOptions" :popup="true" style="border:none; max-height: 80vh; overflow-y: scroll;" />
									<Button v-if="currentDocument?.documentType && currentDocument?.documentType !== DocumentTypeEnum.Pdf " class="p-shadow-4 p-button-md" type="button" icon="mdi mdi-file-question-outline" severity="secondary" rounded v-tooltip="onHoverDocumentType(currentDocument.documentType)" style="background-color: #a9a9a9" />
								</div>
							</template>
						</Toolbar>
						<hr class="horizontal-line">
						<div class="content-error-messages" v-if="currentDocument?.workflowContext?.step === 'Validation' && !showHeadTraining && !showPositionTraining && !showOcrResults && !showLocatorTest && !showSplitDocument">
							<Message
								v-if="currentDocument?.workflowContext?.step === 'Validation' && !showHeadTraining && !showPositionTraining && !showOcrResults && !showLocatorTest && !showSplitDocument"
								:severity="checkErrorSeverity()"
								:closable="false"
							>
								<div class="p-grid p-jc-between errorMessages">
									<div class="p-col-9">
										<ul>
											<li v-if="!loaded">
												{{$t('Squeeze.General.Loading')}}
											</li>
											<li v-else-if="!currentErrorField && allErrorMessages.lengthOfAllErrors > 0">
												{{$t('Squeeze.Validation.General.PleaseCheckDocument')}}
											</li>
											<li v-else-if="!currentErrorField && allErrorMessages.lengthOfAllErrors === 0">
												{{$t('Squeeze.Validation.General.DocumentOK')}}
											</li>
											<li v-else-if="currentErrorField">
												{{currentErrorField.description }}:
												{{currentErrorField.value.errorText }}
											</li>
										</ul>
									</div>
									<div class="p-col-3 p-text-right p-as-center">
										<Badge v-if="allErrorMessages.lengthOfAllErrors !== 0"
												:value="loaded === false ? '?' : allErrorMessages.lengthOfAllErrors"
												:size="!toolbarButtonText ? '' : 'large'" :severity="allErrorMessages.lengthOfAllErrors === 0 ? 'success' : 'danger'"
												@mouseover="toggleErrorMessages" style="cursor: pointer"
										/>
										<OverlayPanel ref="opBadge">
											<template v-for="field in allErrorMessages.fields" v-bind:key="field.id">
												{{field.description }}:
												{{field.value.errorText }}<br/>
											</template>
										</OverlayPanel>
									</div>
								</div>
							</Message>
						</div>
						<div class="scroll-content" ref="leftSplitterPanel">
							<div :class="!showHeadTraining && !showPositionTraining && !showOcrResults && !showLocatorTest && !showSplitDocument && loaded ? 'p-pl-3 p-pr-3 p-pb-3' :  'p-p-3'">
								<HeadTraining
									v-if="showHeadTraining"
									:fieldTrainingValue="trainingValues"
									:documentClassId="documentClassId"
									:documentId="documentId"
									:documentFields="documentFields"
									:trainingKeyField="trainingKeyField"
									@onFocusField="onFocusFieldTraining"
									@onMarkRegion="onMarkRegion"
								/>
								<PositionTraining
									v-else-if="showPositionTraining"
									:documentClassId="documentClassId"
									:documentId="documentId"
									:tables="tables"
									:trainingKeyField="trainingKeyField"
									:positionTrainingValues="positionTrainingValues"
									@onFocusFieldOfPositionTraining="onFocusFieldOfPositionTraining"
									@onMarkRegion="onMarkRegion"
								/>
								<OcrView :title="$t('Squeeze.DocumentClasses.OCRResults')"
									v-else-if="showOcrResults"
									:documentId="documentId"
									@onMarkLines="onMarkLines"
									@onMarkWord="onMarkWord"
								/>
								<LocatorTesting
									v-else-if="showLocatorTest"
									:documentId="documentId"
									@onMarkWord="onMarkWord"
									:showSingleLinedForm="showLocatorTest"
								/>
								<template v-else>
									<DocumentSplit v-if="showSplitDocument" :documentId="documentId" @onImageClick="onSplitImageClick" @onEmptyList="onEmptyList" />
									<TabView v-else-if="loaded" v-model:activeIndex="activeGroupTab" @tab-change="focusOnFirstField">
										<template v-if="store.state.featureSet.validationFieldLayout">
											<TabPanel v-for="group in documentClassFieldsByGroup" :key="group.id" :header="group.description">
												<ValidationFieldSet
													:ref="'validationFieldSet' + group.id"
													@onBlur="validateDocument"
													@onFocusField="markFieldByRegion"
													@allHeadRefFields="allHeadRefFields"
													@enterDocumentGroup="enterDocumentGroup"
													@onHoverItemAutocomplete="onHoverItemAutocomplete"
													class="p-mb-2"
													:documentFields="documentFields"
													:documentClassFields="group.fields"
													:id="group.id"
													:name="group.name"
													:description="group.description"
													:layoutDetails="fieldLayoutComplete.fieldLayoutRows.find(layout => layout.fieldGroupId === group.id) ? fieldLayoutComplete.fieldLayoutRows.find(layout => layout.fieldGroupId === group.id).rows : []"
													:isReadOnlyMode="isReadOnlyMode"
													:documentId="documentId"
												/>
											</TabPanel>
										</template>
										<TabPanel v-else v-for="group in documentClassFieldsByGroup" :key="group.id" :header="group.description">
											<ValidationFieldSetWithoutLayout
												:ref="'validationFieldSet' + group.id"
												@onBlur="validateDocument"
												@onFocusField="markFieldByRegion"
												@allHeadRefFields="allHeadRefFields"
												@enterDocumentGroup="enterDocumentGroup"
												@onHoverItemAutocomplete="onHoverItemAutocomplete"
												class="p-mb-2"
												:documentFields="documentFields"
												:documentClassFields="group.fields"
												:id="group.id"
												:name="group.name"
												:description="group.description"
												:isReadOnlyMode="isReadOnlyMode"
												:documentId="documentId"
												:activeGroupTab="activeGroupTab"
											/>
										</TabPanel>
									</TabView>
									<TabView v-else class="p-px-2 custom">
										<TabPanel v-for="idx in 3" :key="idx" :header="$t('Squeeze.General.Loading')">
											<ValidationFieldSetSkeleton class="p-mb-2" />
										</TabPanel>
									</TabView>
								</template>
							</div>
						</div>
						<div class="p-p-2" v-if="!showHeadTraining && !showPositionTraining && !showSplitDocument && !showOcrResults && !showLocatorTest && tables.length > 1">
							<Button class="p-shadow-2 p-mr-1" v-for="table in tables" :key="table.id" :label="table.description" @click="setActiveTable(table.id)" />
						</div>
					</div>
				</SplitterPanel>
				<SplitterPanel class="p-d-flex" :size="67" :minSize="40">
					<div v-if="!NEW_SQUEEZE_VIEWER" class="viewer" ref="viewer">
						<Viewer :documentId="documentId" class="p-shadow-2" style="height: 100%" />
					</div>
					<SqueezeViewer
						v-else
						:documentData="currentDocument"
						:documentId="documentId"
						:serverBaseUrl="serverBaseUrl"
						:docBaseUrl="docBaseUrl"
						:authHeader="getUserAuth()"
						:drawingMode="viewerDrawingMode"
						:selectedSpans="viewerMarkedRegions"
						:observeResizing="viewerShouldObserveResizing"
						:viewerSettings="JSON.parse(JSON.stringify(store.state.userSettings.viewSettings.viewerSettings))"
						ref="SqueezeViewer"
						style="height: 100%"
						@areaSelected="onViewerAreaSelected"
						@onMarkRegion="onMarkRegion"
						@toggleResultList="toggleResultList"
					/>
				</SplitterPanel>
			</Splitter>
		</SplitterPanel>
		<SplitterPanel class="p-d-flex" :minSize="25" :size="25" :style="!showTableSplitter || !showTableSplitterGutter || activeTable.hidden ? 'display: none !important' : ''">
			<div class="content" v-if="(!showHeadTraining && !showPositionTraining && !showSplitDocument && !showOcrResults && !showLocatorTest)">
				<div class="scroll-content p-shadow-2">
					<ValidationTable
						:ref="'tableInValidation' + activeTableId"
						v-if="loaded"
						@onChange="validateDocument"
						:table="findTableIndex"
						:documentFields="documentFields"
						:documentId="documentId"
						class="p-shadow-4"
						style="max-height: 100%;"
						@onFocusField="onFocusTableCell"
						@focusLastHeadField="focusLastHeadField"
						:hideButtons="hideTableButtons"
						:isReadOnlyMode="isReadOnlyMode"
						@allTableRefCells="allTableRefCells"
						:customTableActions="customTableActions"
						@executeCustomTableAction="executeCustomValidationAction"
					/>
					<ValidationFieldSetSkeleton v-else />
				</div>
			</div>
		</SplitterPanel>
	</Splitter>

	<Sidebar v-model:visible="showHotKeyList" :baseZIndex="1000" position="right" :modal="true" :showCloseIcon="true" :dismissable="true" @hide="showHotKeyList = false">
		<HotKeyList />
	</Sidebar>

	<Dialog :header="$t('Squeeze.Validation.Log.Log')" v-model:visible="showLog" :modal="true" :closable="true" :dismissableMask="true" :baseZIndex="1000" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
		<LogView :document-id="documentId" />
	</Dialog>

	<DialogOptionsConfirm
		:show="dialogs.deleteDocument.display"
		:options="dialogs.deleteDocument.options"
		:locales="dialogs.deleteDocument.locales"
		@onConfirm="deleteDocument"
		@onClose="dialogs.deleteDocument.display = false"
	/>
	<DialogOptionsConfirm
		:show="dialogs.suspendDocument.display"
		:options="dialogs.suspendDocument.options"
		:locales="dialogs.suspendDocument.locales"
		@onConfirm="suspendDocument"
		@onClose="dialogs.suspendDocument.display = false"
		:currentDocument="currentDocument"
	/>
	<Dialog :header="$t('Squeeze.Validation.Email.SendMail')" v-model:visible="dialogs.sendMail.display" :modal="true">
		<ValidationEmailView :documentId="documentId" @sendSuccess="afterSendSuccess" :documentFields="documentFields" />
	</Dialog>

	<EntryDialog :show="dialogs.changeDocumentClass.display"
		@onClose="dialogs.changeDocumentClass.display = false"
		@onConfirm="saveNewDocumentClass"
		:headerText="$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.Change')"
		:loading="dialogs.changeDocumentClass.loading"
	>
		<template #content>
			<DocumentClassChangeForm
				:classificationClasses="dialogs.changeDocumentClass.classificationClasses"
				:trainDocument="dialogs.changeDocumentClass.trainDocument"
				@onChange="onChangeDocumentClassChangeForm"
			/>
		</template>
	</EntryDialog>
	<DialogLocked
		:showDialog="dialogs.lockedDocument.display"
		:lockingUserId="lockingUserId"
		@closeDialog="dialogs.lockedDocument.display = false"
		@showOnly="onClickDocumentReadOnly()"
		@backToOverview="goBackToValidationList()"
		@unlockDocument="unlockViewedDocument()"
	/>

	<Dialog :header="$t('Squeeze.Validation.General.AddAttachment')" v-model:visible="showUpload" :modal="true" :closable="true" :dismissableMask="true" :baseZIndex="1000" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
		<FileUpload
			@select="onSelectFiles"
			name="documentFile"
			:customUpload="true"
			@uploader="fileUploader"
			:multiple="true"
			:showCancelButton="true"
			:uploadFiles="files"
			:cancelLabel="$t('Squeeze.General.Clear')"
			:customProgress="progress"
			:fileContentHeight="'15.625rem'"
			:uploadLabel="uploadLabel"
			:chooseLabel="$t('Squeeze.General.Choose')"
			@clear="clearFiles"
			@removeFile="removeFile"
			:invalidFileSizeMessage="'{0}: ' + $t('Squeeze.Validation.General.InvalidFileSize') + ' ' + '{1}'"
		/>
	</Dialog>
	<EntryDialog
		:show="showCustomActionDialog"
		@onConfirm="showCustomActionDialog = false"
		@onClose="showCustomActionDialog = false"
		:headerText="$t('PrimeVue.Message')"
		:saveButtonText="$t('Squeeze.General.Confirmation')"
		:showAbortButton="false"
	>
		<template #content>
			{{ customActionDialogMessage }}
		</template>
	</EntryDialog>
	<!--<Dialog :header="$t('Squeeze.Validation.Dialogs.SplitDocument.SplitCommand')"
			v-model:visible="showSplitDocument"
			style="width: 90%"
			position="top"
			:modal="true"
	>
		<DocumentSplit :documentId="documentId" />
	</Dialog>
	-->
	<Dialog :header="$t('Squeeze.Validation.Dialogs.ErrorDocument.Label')" v-model:visible="dialogs.errorDocument.display" :modal="true" :closable="false">
		{{ $t('Squeeze.Validation.Dialogs.ErrorDocument.Error') }}

		<template #footer>
			<Button :label="$t('Squeeze.Queue.QueueList')" icon="mdi mdi-transit-connection-horizontal" class="p-button-text p-justify-end" @click="goBackToQueueList()"/>
		</template>
	</Dialog>

	<Dialog :header="$t('Squeeze.System.Fail')" v-model:visible="showExportError" :modal="true">
		<Message
			:closable="false"
			severity="error"
		>
			{{exportErrorMessage}}
		</Message>
	</Dialog>
</template>

<script lang="ts">
/* eslint max-lines: off */
import {Options, Vue} from 'vue-class-component';
import router from "@/router";
import Card from 'primevue/card';
import ScrollPanel from 'primevue/scrollpanel';
import BlockUI from 'primevue/blockui';
import Toolbar from 'primevue/toolbar';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import SplitButton from 'primevue/splitbutton';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import {AuthTypes, ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {
	BatchClassClassification,
	BoundingBox,
	CustomValidationActionDto,
	CustomValidationActionResponseDto,
	DocumentClassDto,
	DocumentField,
	DocumentSearchRequestDto,
	DocumentTable,
	DocumentTableCell,
	DocumentTableRow,
	DocumentValidationRequestDto,
	FieldTraining,
	PaginationDto,
	SaveValidateAndExportDocumentResponseDto,
	TableColumnTraining,
	ValidationFieldDto
} from '@dex/squeeze-client-ts';
import ValidationFieldSet from '@/apps/squeeze/components/ValidationFieldSet.vue';
import ValidationFieldSetWithoutLayout from '@/apps/squeeze/components/ValidationFieldSetWithoutLayout.vue';
import ValidationFieldSetSkeleton from '@/apps/squeeze/components/ValidationFieldSetSkeleton.vue';
import DialogOptionsConfirm from '@/apps/squeeze/components/DialogOptionsConfirm.vue';
import Viewer from "@/apps/squeeze/components/Viewer.vue";
import {BoundingBoxOld, ViewerClient} from "@/apis/squeeze-viewer/ViewerClient";
import ValidationTable from "@/apps/squeeze/components/ValidationTable.vue";
import Dropdown from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import Listbox from 'primevue/listbox';
import Sidebar from 'primevue/sidebar';
import ValidationEmailView from "@/apps/squeeze/views/ValidationEmailView.vue";
import Dialog from 'primevue/dialog';
import DocumentClassChangeForm from "@/apps/squeeze/components/DocumentClassChangeForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import SinglePromise from "@/util/SinglePromise";
import DialogLocked from "@/apps/squeeze/components/DialogLocked.vue";
import HeadTraining from "@/apps/squeeze/views/HeadTraining.vue";
import DocumentSplit from "@/apps/squeeze/components/DocumentSplit.vue";
import PositionTraining from "@/apps/squeeze/views/PositionTraining.vue";
import {RouteLocationRaw} from "vue-router";
import {useSqueezeStore} from "@/apps/squeeze/store";
import OcrView from "@/apps/squeeze/views/OcrView.vue";
import {Document, DocumentFieldValue, ErrorDto} from "@dex/squeeze-client-ts";
import LocatorTesting from "@/apps/squeeze/views/LocatorTesting.vue";
import {SqueezeViewer} from "@dex/squeeze-viewer";
import HotKeyList from "@/apps/squeeze/components/HotKeyList.vue";
import Message from 'primevue/message';
import Badge from 'primevue/badge';
import LogView from "@/apps/administration/views/squeeze/log/LogView.vue";
import FileUpload from "@/components/DexFileUpload.vue";
import {nextTick} from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import {GridStackFieldDetails} from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldsLayoutView.vue";
import {CustomAction} from "@/components/VerticalNavbar.vue";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;
import DocumentTypeEnum = Document.DocumentTypeEnum;

interface DocumentClassFieldGroup {
	id: number | undefined;
	name: string;
	description: string;
	type: number;
	fields: DocumentField[] | undefined;
}

interface SurroundingDocumentIds {
	first?: number;
	random?: number;
	prev?: number;
	self?: number;
	next?: number;
	last?: number;
}

interface FieldInSameLine extends DocumentField {
	sameLineAsPreviousField?: boolean;
	colSize?: string;
	row?: number;
	offsetSize?: number;
}

interface ErrorFields {
	fields?: DocumentField[];
	lengthOfAllErrors?: number;
}

interface FieldGroupDetails {
	fieldGroupId: number;
	fieldLayout: GridStackFieldDetails[];
}

interface FieldGroupLayout {
	fieldGroupId: number;
	fieldLayout: string;
}

@Options({
	name: "Validation",
	components: {
		HotKeyList,
		OcrView,
		LocatorTesting,
		HeadTraining,
		PositionTraining,
		Card,
		ValidationFieldSet,
		ValidationFieldSetWithoutLayout,
		ScrollPanel,
		ValidationFieldSetSkeleton,
		Viewer,
		BlockUI,
		DialogOptionsConfirm,
		ValidationTable,
		Dropdown,
		Fieldset,
		Listbox,
		Sidebar,
		Splitter,
		SplitterPanel,
		Toolbar,
		SplitButton,
		ValidationEmailView,
		Dialog,
		DocumentClassChangeForm,
		EntryDialog,
		DialogLocked,
		DocumentSplit,
		OverlayPanel,
		Menu,
		Message,
		Badge,
		LogView,
		FileUpload,
		SqueezeViewer,
		TabView,
		TabPanel,
	},
	props: {
		documentId: Number,
		documentClassId: Number,
		searchRequest: Object,
		pagination: Object,
		tableSortStart: Array,
		isUploadShown: {
			type: Boolean,
			default: false,
		},
		searchId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		mode: function() {
			switch(this.mode) {
			case "readonly": this.setReadOnlyMode(); break;
			case "edit": break;
			default: break;
			}
		},
		'dialogs.lockedDocument.locked': { // NOTWENDIG?
			handler: function() {
				if (this.dialogs.lockedDocument.locked) {
					this.dialogs.lockedDocument.display = true;

					// If the Document is locked, it can only be viewed as read-only
					this.setReadOnlyMode();
				}
				else {
					this.dialogs.lockedDocument.display = false;
				}
			},
			immediate: true,
		},
		"$route": function() {
			this.onRouteChange();
		},
		'$i18n.locale': function() {
			this.initToolbarOptions();
		},
	},
	computed: {
		DocumentTypeEnum() {
			return DocumentTypeEnum
		},
		Document() {
			return Document
		},
		findTableIndex: function() {
			const tableIndex = this.tables.findIndex((table: DocumentTable) => table.id === this.activeTableId);
			if(tableIndex == -1) {
				return null;
			}

			return this.tables[tableIndex];
		},
		disableBrowseButtons() {
			return this.loaded != true;
		},
		docBaseUrl() {
			return `${ClientManager.getInstance().getSqueezeBasePath()}/documents/${this.documentId}`
		},
		viewerDrawingMode() {
			return this.activePositionTraining === 'columnRegion' ? 'column' : 'rect';
		},
	},
	beforeRouteEnter(to: any, from: any, next: any) {
		next((vm: any) => {
			// Set last route
			vm.lastRoute = from as any;
		})
	},
	emits: ["onRowSelect"],
})
export default class Validation extends Vue {

	/** Current Vuex-Store */
	store = useSqueezeStore();

	NEW_SQUEEZE_VIEWER = this.store.state.featureSet.v2Viewer ? this.store.state.userSettings.viewSettings.v2Viewer : this.store.state.featureSet.v2Viewer;

	/** Current mode of component */
	mode = "edit";

	/** Is the current mode of component readonly? */
	isReadOnlyMode: boolean = false;

	/** ID of document */
	documentId!: number;

	/** ID of document class*/
	documentClassId!: number;

	/** Array with the sortings of the table */
	tableSortStart!: string[];

	/** Is upload dialog shown? */
	isUploadShown!: boolean;

	/** Fields of document */
	documentFields: DocumentField[] = [];

	/** All document classes */
	allDocumentClasses: DocumentClassDto[] = [];

	/** Tables of the document */
	tables: DocumentTable[] = [];

	/** Field groups */
	documentClassFieldsByGroup: DocumentClassFieldGroup[] = [];

	serverBaseUrl = (new URL(ClientManager.getInstance().getSqueezeBasePath())).origin;

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	/** Document Class API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** Validation API endpoint */
	validationApi = ClientManager.getInstance().squeeze.validation;

	/** Indicates end of request */
	loaded = false;
	show = true;

	/** DocumentValidationRequestDto for validation purposes  */
	validationRequest!: DocumentValidationRequestDto;

	/** Current Viewer-Client */
	viewerClient?: ViewerClient

	/** Currently active field */
	activeDocumentField?: DocumentField;

	/** Currently active table cell */
	activeTableCell?: DocumentTableCell;

	/** Currently active table row index */
	activeTableRowIndex: number | null = null;

	/** Indicates to block UI components specified */
	blocked = {
		buttons: {
			save: false,
			suspend: false,
			delete: false,
			extract: false,
			changeDocumentClass: false,
			split: false,
			sendMail: false,
			training: false,
			testing: false,
			attachment: false,
			menu: false,
		},
	}

	/** Currently active table-id */
	activeTableId?: number | null = null;

	/** Currently active table */
	activeTable?: DocumentTable | null = null;

	/** Currently active Batch Class Id */
	batchClassId?: number = 0;

	/** Currently acitve Document-Class */
	newDocumentClass = 0;

	/** Should the table buttons be hidden? */
	hideTableButtons = false;

	/** Confirm dialog configuration - no options = no dropdown in dialog */
	dialogs = {
		deleteDocument: {
			display: false,
			options: [{label: "", value: ""}],
			locales: {
				header: "Squeeze.Validation.Dialogs.DeleteDocument.Header",
				notice: {
					selectReason: "Squeeze.Validation.Dialogs.DeleteDocument.Notice.SelectReason",
					comment: "Squeeze.Validation.Dialogs.DeleteDocument.Notice.Comment",
				},
				selectPlaceholder: "Squeeze.Validation.Dialogs.DeleteDocument.SelectPlaceholder",
				buttons: {
					confirm: "Squeeze.Validation.Buttons.Delete",
					abort: "Squeeze.Validation.Buttons.Abort",
				},
			},
		},
		suspendDocument: {
			display: false,
			options: [],
			locales: {
				header: "Squeeze.Validation.Dialogs.SuspendDocument.Header",
				notice: {
					selectReason: "Squeeze.Validation.Dialogs.SuspendDocument.Notice.SelectReason",
					comment: "Squeeze.Validation.Dialogs.SuspendDocument.Notice.Comment",
				},
				selectPlaceholder: "Squeeze.Validation.Dialogs.SuspendDocument.SelectPlaceholder",
				buttons: {
					confirm: "Squeeze.Validation.Buttons.Suspend",
					abort: "Squeeze.Validation.Buttons.Abort",
				},
			},
		},
		lockedDocument: {
			display: false,
			locked: false,
		},
		changeDocumentClass: {
			display: false,
			loading: false,
			trainDocument: true,
			classificationClasses: [] as BatchClassClassification[],
		},
		sendMail: {
			display: false,
		},
		errorDocument: {
			display: false,
		},
	}

	/** Options of the toolbar. Due to the translations, those are defined in "mounted()" */
	toolbarOptions?: any[] = [];

	/** Batch-Class API endpoint */
	batchClassApi = ClientManager.getInstance().squeeze.batchClass;

	/** Single Promise for Validation Requests. Only the latest Request will be resolved */
	singlePromiseValidation = new SinglePromise();

	/** Is the document locked by the current user? */
	lockedByCurrentUser = false;

	/** Id of the user that is currently blocking the document **/
	lockingUserId?: number|null = null;

	/** Should the Toolbar Button Text be shown? */
	toolbarButtonText: boolean = false;

	/** Should the Split-Document be shown? */
	showSplitDocument = false;

	/** Hide Validation-Buttons? */
	hideValidationButtons = false;

	/** Is the Head Training visible? */
	showHeadTraining = false;

	/** Is the Position Training visible? */
	showPositionTraining = false;

	/** Is the Locator test visible? */
	showLocatorTest = false;

	/** Is the Table Splitter visible? */
	showTableSplitter = true;

	/** Is the Table Splitter Gutter visible? */
	showTableSplitterGutter= false;

	/** Is the OCR Results View visible? */
	showOcrResults = false;

	/** Time of MouseDown Event by Splitter */
	startTimeByMouseDown: number = 0;

	/** Currently active document group tab */
	activeGroupTab: number = 0;

	/** List with document fields for training */
	trainingValues: FieldTraining = {
		id: 0,
		fieldId: 0,
		documentClassId: 0,
		trainingKeyValue: "",
		keyWordPattern: "",
		valuePattern: "",
		valueRegion: {
			page: 0,
			x0: 0,
			y0: 0,
			x1: 0,
			y1: 0,
		},
		keyWordRegion: {
			page: 0,
			x0: 0,
			y0: 0,
			x1: 0,
			y1: 0,
		},
	}

	trainingKeyField: DocumentField|null = null;

	/** Currently active table-field in training */
	activeFieldTraining = '';

	positionTrainingValues: TableColumnTraining = {
		"id": 0,
		"documentClassId": 0,
		"tableId": 0,
		"columnId": 0,
		"trainingKeyValue": "",
		"columnRegion": {
			"page": 0,
			"x0": 0,
			"y0": 0,
			"x1": 0,
			"y1": 0,
		},
		"valuePattern": "",
	}

	/** Currently active table-field in positionTraining */
	activePositionTraining = '';

	/** Search request for document search filtering */
	searchRequest: DocumentSearchRequestDto = {}

	/** Pagination info for document search */
	pagination: PaginationDto = {
		pageSize: 25,
		page: 0,
		total: 0,
	}

	/** Is the document read-only? */
	readonly = false;

	/** Resize observe of left splitter panel */
	resizeObserver: any = null;

	/** Current Search-Elements, used for pagination */
	searchElements: Document[] | null = null;

	/** Viewer marked regions (V2) */
	viewerMarkedRegions: BoundingBox[] = [];

	viewerShouldObserveResizing = false;

	/** Array with all headRefFieldElements */
	headRefFieldElements: any[] = [];

	/** Array with all tableRefElements */
	allTableRefElements: any[] = [];

	/** Is hotKey-List visible? */
	showHotKeyList: boolean = false;

	/** Is Log visible? */
	showLog: boolean = false;

	/** Is attachment upload visible? */
	showUpload: boolean = false

	/** Message that shows the number of the currently uploaded documents  */
	uploadLabel = "";

	/** List of all files to be uploaded */
	files = [{
		uploadFinished: false,
		loading: false,
		errorText: '',
		error: false,
	}]

	/** Current Progress of upload */
	progress = 0;

	/** Array with all error messages of fields  */
	allErrorMessages: ErrorFields = {
		fields: [],
		lengthOfAllErrors: 0,
	};

	/** Contains the field that the error messages should be currently shown of */
	currentErrorField: DocumentField | null = null;

	/** Name of the last  route */
	lastRoute: any = "";
	blockBrowseButtons: boolean = false;

	/** Should the Export Error Message be shown? */
	showExportError: boolean = false;
	exportErrorMessage: string = "";

	/** Field Layout Details */
	fieldLayoutDetails: FieldGroupDetails[] = [];

	/** Field Layout Complete */
	fieldLayoutComplete: {fieldLayout: FieldGroupDetails[]; fieldLayoutRows: any[]} = {fieldLayout: this.fieldLayoutDetails, fieldLayoutRows: []}

	/** All custom actions */
	customActions: CustomValidationActionDto[] = [];

	/** All table custom actions */
	customTableActions: CustomValidationActionDto[] = [];

	/** Is confirm dialog for execute a action shown? */
	showCustomActionDialog: boolean = false;

	/** Custom Action dialog message */
	customActionDialogMessage: string = '';

	/** Contains the full current document */
	currentDocument: Document|null = null;

	/** Has the validation been triggered once and has the table splitter been set? */
	tableSplitterSetAfterValidation: boolean = false;

	/** Current Search request for document search filtering when search request is empty */
	currentSearchRequest: DocumentSearchRequestDto = {};

	/** Current workflow context of document */
	documentWorkflowContext: any = {};

	/** On page ready */
	async mounted() {
		// get current search request from local storage
		const currentSearchRequestInLocalStorage = localStorage.getItem('searchRequest');
		if (Object.keys(this.searchRequest).length === 0 && currentSearchRequestInLocalStorage) {
			this.currentSearchRequest = JSON.parse(currentSearchRequestInLocalStorage);
			localStorage.removeItem('searchRequest');
		}

		window.addEventListener('beforeunload', this.onUnload, true);
		window.addEventListener('onbeforeunload', this.onUnload, true);
		window.addEventListener('resize', this.showToolbarButtonText, true);
		window.addEventListener('keydown', this.onKeydown.bind(this), true);
		window.addEventListener('dragover', this.triggerShowDragDialog.bind(this), true);

		if (this.$route.query.parent && this.$route.query.parent === "QueueList") {
			this.blockBrowseButtons = true;
		}

		this.initDialogOptions();
		this.initToolbarOptions();
		await this.showToolbarButtonText();
		//this.loadQueryParams();
		this.reloadData();
		this.setupViewer();
		this.getAllDocumentClasses();
		this.getCustomActions();

		// width of left splitter panel by start load
		const leftSplitterPanel: any = this.$refs.leftSplitterPanel;
		this.resizeObserver = new ResizeObserver(() => {
			if (leftSplitterPanel.offsetWidth <= 605) {
				this.toolbarButtonText = false;
			} else {
				this.toolbarButtonText = true;
			}
		});
		// observe the leftSplitterPanel
		this.resizeObserver.observe(leftSplitterPanel);
	}

	/** Get all custom actions */
	getCustomActions() {
		this.validationApi.getCustomValidationActions()
			.then((scripts: CustomValidationActionDto[]) => {
				const customScripts = scripts.filter(script => script.placement === 'menu');
				if (customScripts) {
					this.customActions = customScripts;
				}
				const customTableScripts = scripts.filter(script => script.placement === 'table');
				if (customTableScripts) {
					this.customTableActions = customTableScripts;
				}
			})
			.catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/**
	 * Execute the custom action in validation
	 * @param actionId
	 */
	executeCustomValidationAction(actionId: string) {
		this.validationApi.executeCustomValidationAction(actionId, this.documentId, this.validationRequest)
			.then((actionResponse: CustomValidationActionResponseDto) => {
				if (actionResponse.errorMessage) {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), actionResponse.errorMessage);
					return;
				}

				// show document which is in return value
				switch(actionResponse.returnType) {
				case 'showDocument': {
					if (!actionResponse.returnValue.documentId) {
						return;
					}
					this.gotoDocument(Number(actionResponse.returnValue.documentId));
					break;
				}
				case 'stay': {
					if (actionResponse.fields) {
						this.handleValidationFieldResponse(actionResponse.fields);
					}
					if (actionResponse.tables) {
						this.tables = actionResponse.tables;
					}
					this.validateDocument();
					break;
				}
				}

				// set returnValue message
				this.customActionDialogMessage  = actionResponse.returnValue.message;
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			})
			.finally(() => {
				// show dialog when the custom action has a message
				if (this.customActionDialogMessage) {
					this.showCustomActionDialog = true;
				}
			})
	}

	getAllDocumentClasses() {
		this.documentClassApi.getAllDocumentClasses()
			.then(data => {
				this.allDocumentClasses = data;
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			});
	}

	getUserAuth() {
		if (ClientManager.getInstance().login.activeAuth === AuthTypes.Bearer) {
			return { 'Authorization': "Bearer " + ClientManager.getInstance().keycloakOptions.token }
		}

		const { username, password } = ClientManager.getInstance().getSqueezeCredentials();
		return { 'Authorization': "Basic " + btoa(`${username}:${password}`) }
	}

	onSplitterMouseDown(event: MouseEvent) {
		// Only run if splitter-gutter was clicked!
		const target = event.target as HTMLElement;
		if (!target.matches('.p-splitter-gutter, .p-splitter-gutter *')) {
			return;
		}

		// Set startTime of MouseDown
		this.startTimeByMouseDown = event.timeStamp;

		this.disableViewerOnResizing();
	}

	onSplitterMouseUp(event: MouseEvent) {
		// Only run if splitter-gutter was clicked!
		const target = event.target as HTMLElement;
		if (!target.matches('.p-splitter-gutter, .p-splitter-gutter *')) {
			return;
		}

		// Get Time difference between MouseDown and MouseUp Event
		const timeBetweenMouseDownAndUp = (event.timeStamp - this.startTimeByMouseDown);
		if (!this.showHeadTraining && !this.showPositionTraining && !this.showSplitDocument && !this.showOcrResults && !this.showLocatorTest) {
			if (target.matches('.p-splitter-panel-nested .p-splitter-gutter-handle')) {
				// horizontal splitter gutter handle --> do nothing by click
				this.enableViewerAfterResizing();
				return;
			} else if (target.matches('.p-splitter-gutter-handle')) {
				// Get Time difference between MouseDown and MouseUp Event
				if (timeBetweenMouseDownAndUp <= 150) {
					this.toggleSplitterTable();
				}
			}
		}

		this.enableViewerAfterResizing();
	}

	onSplitterResizeEnd() {
		this.enableViewerAfterResizing();
	}

	toggleResultList() {
		this.disableViewerOnResizing();
		nextTick(() => {
			this.enableViewerAfterResizing();
		})
	}

	disableViewerOnResizing() {
		this.viewerShouldObserveResizing = false;

		// Set Pointer Event on Viewer to none, when resize start
		if (this.NEW_SQUEEZE_VIEWER) {
			(this.$refs.SqueezeViewer as any).$el.style.pointerEvents = 'none';
		} else {
			(this.$refs.viewer as any).style.pointerEvents = 'none';
		}
	}

	enableViewerAfterResizing() {
		this.viewerShouldObserveResizing = true;

		// Set Pointer Event on Viewer to auto, when resize end
		if (this.NEW_SQUEEZE_VIEWER) {
			(this.$refs.SqueezeViewer as any).$el.style.pointerEvents = 'auto';
		} else {
			(this.$refs.viewer as any).style.pointerEvents = 'auto';
		}
	}

	/** Set Table Splitter to disabled or enabled */
	toggleSplitterTable() {
		this.showTableSplitter = !this.showTableSplitter;
	}

	private loadQueryParams() {
		const wfQuery = this.$route.query.wf;
		if(wfQuery && typeof wfQuery === "string") {
			const wfValueFilters = JSON.parse(decodeURI(wfQuery));
			if(wfValueFilters) {
				this.searchRequest.workflowContextFilters = wfValueFilters;
			}
		}
		const fieldQuery = this.$route.query.f;
		if(fieldQuery && typeof fieldQuery === "string") {
			const fieldValueFilters = JSON.parse(decodeURI(fieldQuery));
			if(fieldValueFilters) {
				this.searchRequest.fieldFilters = fieldValueFilters;
			}
		}
	}

	private async onViewerAreaSelected(payload: { words: string[]; boundingBox: BoundingBox }) {
		// Only used by new Squeeze-Viewer

		const { words, boundingBox } = payload;

		// console.log('onViewerAreaSelected', payload);

		// if (words && words.length) {
		// 	const str = encodeURIComponent(words.join(' '));
		// 	const url = ClientManager.getInstance().getSqueezeBasePath() + `/util/rpc/formatString?str=${str}`;
		// 	const res = await fetch(url, {
		// 		method: 'GET',
		// 		headers: { Authorization: this.getUserAuth() },
		// 	});
		// 	const formatted = await res.json();

		// 	console.log('formatted response in onViewerAreaSelected', formatted);
		// }

		// if readOnlyMode true, then prevent to get the value of draw area in viewer
		if (this.isReadOnlyMode && (!this.showHeadTraining && !this.showPositionTraining)) {
			return
		}

		const value = words.join(' ');
		const boundingBoxOld: BoundingBoxOld = {
			bottom: boundingBox.y1 || 0,
			left: boundingBox.x0 || 0,
			right: boundingBox.x1 || 0,
			top: boundingBox.y0 || 0,
			page: String(boundingBox.page || 0),
		};

		this.viewerMarkedRegions = [boundingBox];

		if (this.showHeadTraining) {
			if (this.activeFieldTraining === 'keyWordPattern') {
				this.trainingValues.keyWordPattern = value;
				if (this.trainingValues.keyWordRegion) {
					this.mapBoundingBoxDtoToViewerBbox(this.trainingValues.keyWordRegion, boundingBoxOld);
				}
			}
			if (this.activeFieldTraining === 'valuePattern') {
				this.trainingValues.valuePattern = value;
				if (this.trainingValues.valueRegion) {
					this.mapBoundingBoxDtoToViewerBbox(this.trainingValues.valueRegion, boundingBoxOld);
				}
			}
		} else if (this.showPositionTraining) {
			if (this.activePositionTraining === 'valuePattern') {
				this.positionTrainingValues.valuePattern = value;
			}
			if (this.activePositionTraining === 'columnRegion') {
				if (this.positionTrainingValues.columnRegion) {
					this.mapBoundingBoxDtoToViewerBbox(this.positionTrainingValues.columnRegion, boundingBoxOld);
				}
			}
		} else {
			if (this.activeDocumentField && this.activeDocumentField.id) {
				// FIXME: Is there a better way to assert non-null?
				const fieldIndex = this.getDocumentFieldIndex(this.activeDocumentField.name as any);
				const documentField = this.documentFields[fieldIndex];

				if(documentField && !this.activeDocumentField.readonly) {
					const documentFieldValue = documentField.value;
					if (documentFieldValue) {
						if (documentField.dataType?.toLowerCase() == "amount") {
							documentFieldValue.value = this.formatAmount(value);
						} else {
							documentFieldValue.value = value;
						}

						if (documentField && documentField.lookup && documentField.lookup.active && !documentField.lookup.allowCustomValues) {
							const eventSent = {
								query: documentFieldValue.value,
							}

							const currentValidationFieldSet = 'validationFieldSet' + documentField.fieldGroupId;
							if (this.$refs[currentValidationFieldSet] as any) {
								await (this.$refs[currentValidationFieldSet] as any).checkAutocompleteValues(eventSent as any, documentField as any, documentFieldValue.value);
							}
						}

						documentFieldValue.boundingBox = boundingBox;
						await this.validateDocument();
					}
				}
			}
			else if (this.activeTable && this.activeTableCell && this.activeTableCell.columnId) {
				const column = (this.activeTable.columns || []).find(column => column.name === this.activeTableCell?.columnName);

				if (column && column.dataType && !column.readonly) {
					if (column.dataType.toLowerCase() == "amount") {
						this.activeTableCell.value = this.formatAmount(value);
					} else {
						this.activeTableCell.value = value;
					}

					if (column.lookup && column.lookup.active && !column.lookup.allowCustomValues) {
						const eventSent = {
							query: this.activeTableCell.value,
						}

						const currentValidationTable = 'tableInValidation' + this.activeTableId;
						if (this.$refs[currentValidationTable] as any) {
							await (this.$refs[currentValidationTable] as any).checkAutocompleteValues(eventSent as any, column as any, this.activeTableCell, this.activeTableCell.value, this.activeTableRowIndex, this.activeTableId);
						}
					}

					await this.validateDocument();
				}
			}
		}
	}

	private initDialogOptions() {
		this.dialogs.deleteDocument.options = [
			{
				label: this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Duplicate'),
				value: "duplicate",
			},
			{
				label: this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Dunning'),
				value: "dunning",
			},
			{
				label: this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Advertisement'),
				value: "advertisement",
			},
			{
				label: this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Misc'),
				value: "misc",
			},
		];
	}

	setupViewer() {
		// Only used by old Squeeze-Viewer

		const iframe = document.getElementById("SqueezeViewer");
		if (iframe instanceof HTMLIFrameElement && iframe.contentWindow != null) {
			this.viewerClient = new ViewerClient(iframe.contentWindow);

			this.viewerClient.onSelectArea = (value, boundingBox) => {
				// if readOnlyMode true, then prevent to get the value of draw area in viewer
				if (this.isReadOnlyMode && (!this.showHeadTraining && !this.showPositionTraining)) {
					return
				}

				if (this.showHeadTraining) {
					if (this.activeFieldTraining === 'keyWordPattern') {
						this.trainingValues.keyWordPattern = value;
						if (this.trainingValues.keyWordRegion) {
							this.mapBoundingBoxDtoToViewerBbox(this.trainingValues.keyWordRegion, boundingBox);
						}
					}
					if (this.activeFieldTraining === 'valuePattern') {
						this.trainingValues.valuePattern = value;
						if (this.trainingValues.valueRegion) {
							this.mapBoundingBoxDtoToViewerBbox(this.trainingValues.valueRegion, boundingBox);
						}
					}
				} else if (this.showPositionTraining) {
					if (this.activePositionTraining === 'valuePattern') {
						this.positionTrainingValues.valuePattern = value;
					}
					if (this.activePositionTraining === 'columnRegion') {
						if (this.positionTrainingValues.columnRegion) {
							this.mapBoundingBoxDtoToViewerBbox(this.positionTrainingValues.columnRegion, boundingBox);
						}
					}
				} else {
					if (this.activeDocumentField) {
						// FIXME: Is there a better way to assert non-null?
						const fieldIndex = this.getDocumentFieldIndex(this.activeDocumentField.name as any);
						const documentField = this.documentFields[fieldIndex];
						const documentFieldValue = this.documentFields[fieldIndex].value;

						if(documentField && documentFieldValue && !this.activeDocumentField.readonly) {
							if (documentField.dataType?.toLowerCase() == "amount") {
								this.documentFields[fieldIndex].value!.value = this.formatAmount(value as any);
							} else {
								this.documentFields[fieldIndex].value!.value = value;
							}
							this.validateDocument();
						}
					}
					else if (this.activeTableCell && this.activeTableCell.columnId) {
						const column = this.activeTable!.columns?.find(column => column.name === this.activeTableCell?.columnName);

						if(column && column.dataType) {
							if (column.dataType.toLowerCase() == "amount") {
								//this.activeTableCell.value = this.parseNumbers(value) as any; // TODO: Add Number fields to Table
								this.activeTableCell.value = value;
							} else {
								this.activeTableCell.value = value;
							}
							this.validateDocument();
						}
					}
				}
			}

			this.viewerClient.onOpenFieldTraining = () => {
				const principal = this.getDocumentFieldValue("Creditor");
				principal ? this.viewerClient?.openFieldTraining(principal) : ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Viewer.Error.NoCreditorField'));
			}

			this.viewerClient.onOpenItemTraining = () => {
				const principal = this.getDocumentFieldValue("Creditor");
				principal ? this.viewerClient?.openItemTraining(principal) : ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Viewer.Error.NoCreditorField'));
			}

			this.viewerClient.onOpenFieldTrainingList = () => {
				const principal = this.getDocumentFieldValue("Creditor");
				principal ? this.viewerClient?.openFieldTrainingList(principal) : ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Viewer.Error.NoCreditorField'));
			}
		}
	}

	/** Validation Key Combination */
	onKeydown(event: KeyboardEvent) {
		// save document - If key strg/ctrl and S pressed, then validates a document and then exports it
		if (event.code === "KeyS" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			event.preventDefault();
			if (!this.blocked.buttons.save) {
				this.validateAndExportDocument();
			}
		}
		// suspend document - If key strg/ctrl and B pressed, then suspend the document
		if (event.code === "KeyB" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			event.preventDefault();
			if (!this.blocked.buttons.suspend) {
				this.showDialog('suspendDocument');
			}
		}
		// delete document - If key strg/ctrl and L pressed, then open the delete dialog of a document
		if (event.code === 'KeyL' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			event.preventDefault();
			this.showDialog('deleteDocument');
		}
		// open hotKey-List - If key F1 pressed, then open the hotKey-List
		if (event.code === 'F1') {
			this.showHotKeyList = !this.showHotKeyList;
		}
		// open headTraining - If key strg/ctrl and F2 pressed, then open the headTraining
		if (event.code === 'F2' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if(!this.blocked.buttons.training) {
				if(this.showPositionTraining) {
					this.showPositionTraining = false;

					// reset markedRegions in viewer
					this.viewerMarkedRegions = [];
				} else if(this.showLocatorTest) {
					this.showLocatorTest = false;
				}
				this.showHeadTraining = true;
				this.hideValidationButtons = true;
				this.toggleSplitterTable();
			} else {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Training.NoTrainingAccessMessage', { training: this.$t('Squeeze.Training.FieldTraining')}));
			}
		}
		// open positionTraining - If key strg/ctrl and F3 pressed, then open the positionTraining
		if (event.code === 'F3' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if(!this.blocked.buttons.training) {
				if(this.showHeadTraining) {
					this.showHeadTraining = false;
				} else if(this.showLocatorTest) {
					this.showLocatorTest = false;
				}
				this.showPositionTraining = true;
				this.hideValidationButtons = true;
				this.toggleSplitterTable();
			} else {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Training.NoTrainingAccessMessage', { training: this.$t('Squeeze.Training.PositionTraining')}));
			}
		}
		// open Log-List - If key F4 pressed, then open the Log-List
		if (event.code === 'F4' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if (this.store.state.scopes.sqzAdmin && !this.blocked.buttons.changeDocumentClass) {
				this.showLog = !this.showLog;
			}
		}
		// open locatorTesting - If key strg/ctrl and F12 pressed, then open the locatorTesting
		if (event.code === 'F12' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if(this.showHeadTraining) {
				this.showHeadTraining = false;
			} else if(this.showPositionTraining) {
				this.showPositionTraining = false;

				// reset markedRegions in viewer
				this.viewerMarkedRegions = [];
			}
			this.showLocatorTest = true;
			this.hideValidationButtons = true;
			this.toggleSplitterTable();
		}
		// delete current line  - If key strg/ctrl and Entf pressed, then delete the current line
		if (event.code === 'Delete' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey) || event.code === 'Backspace' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if (this.activeTableRowIndex) {
				const currentValidationTable = 'tableInValidation' + this.activeTableId;
				const tableComponent: any = this.$refs[currentValidationTable];
				tableComponent.deleteRow(this.activeTableRowIndex);
			}
		}
		// insert a new line  - If key strg/ctrl and Einf pressed, then insert a new line
		if ((event.code === 'Insert' || event.code === 'KeyY') && !event.shiftKey && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if (this.activeTableRowIndex) {
				const currentValidationTable = 'tableInValidation' + this.activeTableId;
				const tableComponent: any = this.$refs[currentValidationTable];
				tableComponent.createNewRow(this.activeTableRowIndex as number);
			}
		}
		// copy the current line & insert in next line  - If key strg/ctrl + shift and Einf pressed, then copied the current line and insert in next line
		if ((event.code === 'Insert' || event.code === 'KeyY') && event.shiftKey && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			if (this.activeTableRowIndex) {
				const currentValidationTable = 'tableInValidation' + this.activeTableId;
				const tableComponent: any = this.$refs[currentValidationTable];
				tableComponent.copyRow(this.activeTableRowIndex as number);
			}
		}

		// v1 viewer shortcuts
		if (this.viewerClient) {
			// viewer-image optimized width - If key POS1 pressed, then optimized the width of viewer-image
			if (event.code === 'Home' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				this.viewerClient.toggleFullWidth();
			}
			// viewer-image optimized height - If key strg/ctrl and END pressed, then optimized the height of viewer-image
			if (event.code === 'End' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				this.viewerClient.toggleFullHeight();
			}
			// viewer-image scroll up - If key PageUp pressed, then scroll up in viewer-image
			if (event.code === 'PageUp') {
				event.preventDefault();
				this.viewerClient.pageUp();
			}
			// viewer-image scroll down - If key PageDown pressed, then scroll down in viewer-image
			if (event.code === 'PageDown') {
				event.preventDefault();
				this.viewerClient.pageDown();
			}
		} else if (this.NEW_SQUEEZE_VIEWER) {
			// v2 viewer shortcuts
			// viewer-image optimized width - If key POS1 pressed, then optimized the width of viewer-image
			if (event.code === 'Home' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				(this.$refs.SqueezeViewer as any).onClickResetZoom();
			}
			// viewer-image optimized height - If key strg/ctrl and END pressed, then optimized the height of viewer-image
			if (event.code === 'End' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				(this.$refs.SqueezeViewer as any).onClickHeightZoom();
			}
			// viewer-image scroll up - If key PageUp pressed, then scroll up in viewer-image
			if (event.code === 'PageUp') {
				event.preventDefault();
				(this.$refs.SqueezeViewer as any).openNextPage();
			}
			// viewer-image scroll down - If key PageDown pressed, then scroll down in viewer-image
			if (event.code === 'PageDown') {
				event.preventDefault();
				(this.$refs.SqueezeViewer as any).openPreviousPage();
			}
			// viewer-image fixation - If key strg/ctrl and Space pressed, then fixate the viewer-image
			if (event.code === 'Space' && event.ctrlKey) {
				event.preventDefault();
				(this.$refs.SqueezeViewer as any).toggleSetView();
			}
		}

		// check length of custom actions in menu to check
		if (this.customActions.length) {
			this.onCustomActionShortcut(event, this.customActions);
		}

		// check length of custom actions in table
		if (this.customTableActions.length) {
			this.onCustomActionShortcut(event, this.customTableActions);
		}
	}

	/**
	 * On keydown to check the shortcut, so that execute the custom validation action
	 * @param event
	 * @param allCustomActions
	 */
	onCustomActionShortcut(event: KeyboardEvent, allCustomActions: CustomValidationActionDto[]) {
		allCustomActions.forEach((action: CustomValidationActionDto) => {
			if (action && action.shortcut && action.shortcut.key) {
				const currentEventCodeKeys = event.code.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g);
				if ((currentEventCodeKeys && currentEventCodeKeys[1] === action.shortcut.key.toUpperCase()) && (
					(action.shortcut.ctrl && !action.shortcut.alt && ((event.ctrlKey || event.metaKey) && !event.altKey))
					|| (action.shortcut.ctrl && action.shortcut.alt && (event.altKey && (event.ctrlKey || event.metaKey)))
					|| (action.shortcut.alt && !action.shortcut.ctrl && (event.altKey && (!event.ctrlKey || !event.metaKey))))
				) {
					event.preventDefault();
					this.executeCustomValidationAction(action.id as string);
				}
			}
		})
	}

	onUnload() {
		this.unlockDocument(true);

		// set search request in local storage
		const currentSearchRequestInLocalStorage = localStorage.getItem('searchRequest');
		if (this.searchRequest && !currentSearchRequestInLocalStorage) {
			localStorage.setItem('searchRequest', JSON.stringify(this.searchRequest));
		}
	}

	onRouteChange() {
		// Reload data uses the documentId and documentClassId props which are bound to route params
		// When this watcher is called, those props do NOT yet contain the values of the `to` route params.
		// Therefore the data is reloaded on the next tick.
		// Before that happens, unlock the document we are leaving
		if (this.lockedByCurrentUser) {
			this.unlockDocument(true);
		}

		if (this.$route.name === "ValidateEntry") {
			this.$nextTick(() => {
				this.reloadData();
			});
		}
	}

	/** Set the Viewer BoundingBox values in values */
	mapBoundingBoxDtoToViewerBbox(values: BoundingBox, boundingBox: BoundingBoxOld) {
		values.x0 = boundingBox.left;
		values.y0 = boundingBox.top;
		values.x1 = boundingBox.right;
		values.y1 = boundingBox.bottom;
	}

	parseNumbers(value: string) {
		value = value.replace(/[^0-9.,]/g, "");
		if(value.indexOf(",") != -1) {
			value = value.replaceAll(".", "").replace(",", ".");
		}
		if(value.length > 0) {
			return parseFloat(value);
		} else {
			return 0;
		}
	}

	getDocumentFieldValue(fieldName: string) {
		return this.documentFields[this.getDocumentFieldIndex(fieldName)]?.value?.value;
	}

	/** Is triggered before an Component unmounts. Available since Vue 3.0 */
	beforeUnmount() {
		window.removeEventListener("beforeunload", this.onUnload, true)
		window.removeEventListener("onbeforeunload", this.onUnload, true)
		window.removeEventListener('resize', this.showToolbarButtonText, true);
		window.removeEventListener('keydown', this.onKeydown.bind(this), true);
		window.removeEventListener('dragover', this.triggerShowDragDialog.bind(this), true);

		// Unlock document
		this.unlockDocument(true);

		// Remove the listener when the Component unmounts, otherwise the same listener will be registered multiple times
		if (this.viewerClient) {
			this.viewerClient.removeListener()
		}

		// unobserve the leftSplitterPanel
		const leftSplitterPanel: any = this.$refs.leftSplitterPanel;
		this.resizeObserver.unobserve(leftSplitterPanel);
	}

	/** Return index of document field */
	getDocumentFieldIndex(name: string) {
		return this.documentFields.findIndex(field => field.name == name);
	}

	/** Reload document fields as well as validation fields */
	reloadData() {
		this.loaded = false;
		this.activeGroupTab = 0;
		this.headRefFieldElements = [];
		this.blocked.buttons.save = this.blocked.buttons.suspend = this.blocked.buttons.delete = this.blocked.buttons.menu = true;
		const documentClassFieldsPromise = this.documentClassApi.getAllDocumentClassFields(this.documentClassId);
		const documentPromise = this.documentApi.getDocumentById(this.documentId);
		this.readonly = false;
		this.dialogs.lockedDocument.locked = false;
		this.viewerShouldObserveResizing = false;
		this.isReadOnlyMode = false;
		this.tableSplitterSetAfterValidation = false;

		Promise.all([documentClassFieldsPromise, documentPromise])
			.then(promises => {
				const documentClassFieldCollection = promises[0];
				const document = promises[1];
				this.currentDocument = document;
				const user = this.store.state.user;
				const fieldGroups = document.fieldGroups;
				this.documentClassFieldsByGroup = [];

				// A document may or may not be in a workflow context
				if (document.workflowContext) {
					this.documentWorkflowContext = document.workflowContext;
					this.lockingUserId = document.workflowContext.lockedBy;

					if (document.workflowContext.step !== "Validation") {
						this.readonly = true;
					}
					// There is no user that is blocking the document
					else if (!document.workflowContext.lockedBy) {
						this.lockDocument();
					}
					// If the user.id is equal to the locking user, everything is ok
					else if (user && user.id === document.workflowContext.lockedBy) {
						this.lockedByCurrentUser = true;
					}
					// If the blocking user is not the current user, the document is blocked!
					else {
						this.dialogs.lockedDocument.locked = true;
					}
				} else {
					this.readonly = true;
				}

				document.fields.forEach(field => {
					if (field.dataType?.toLowerCase() === "amount") {
						if (field.value != null) {
							if (field.value.value != null) {
								// FORMAT TO NUMBER
								field.value.value = this.formatAmount(field.value.value);
							} else {
								field.value.value = parseFloat("0") as any;
							}
						}
					}
				});

				this.batchClassId = document.batchClassId;
				this.documentFields = document.fields;
				this.tables = document.tables;

				this.getDocumentClassTrainingKey();

				// Get Classification-Classes for Batch-Class
				if (this.batchClassId) {
					this.batchClassApi.getBatchClassClassifications(this.batchClassId)
						.then(data => {
							this.dialogs.changeDocumentClass.classificationClasses = data.filter(classificationClass => classificationClass.documentClassId !== Number (this.$route.params.documentClassId));

							// Disable Button for Change, if there is no Classification Class to Change to
							if (this.dialogs.changeDocumentClass.classificationClasses && this.dialogs.changeDocumentClass.classificationClasses.length !== 0) {
								this.blocked.buttons.changeDocumentClass = true;
							}
						})
						.catch(response => response.json().then ((err: any) => {
							ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
						}))
				}

				/** Sort by internal Description. TODO: Delete later? */
				this.tables.sort((a, b) => {
					if (a.description && b.description) {
						if (a.description > b.description) {
							return 1;
						}
						else {
							return -1;
						}
					}
					return 0;
				})

				// Get first active table, not yet
				// Get first active table
				for (const table of this.tables) {
					if (table.id) {
						this.activeTableId = table.id;
						this.checkTableBehaviour(table);
						this.getActiveTable();
						break;
					}
				}

				fieldGroups.forEach((fieldGroup) => {
					if(fieldGroup.type === 0) {
						const fieldsOfFieldGroup = documentClassFieldCollection.filter(field => field.fieldGroupId === fieldGroup.id);
						const documentFieldGroup = {
							id: fieldGroup.id,
							name: fieldGroup.name,
							description: fieldGroup.description,
							type: fieldGroup.type,
							fields: fieldsOfFieldGroup.sort(this.sortFieldsByOrder),
						}
						this.documentClassFieldsByGroup.push(documentFieldGroup);
					}
				});

				// check if to get fieldLayout OR sameLineAsPreviousField
				if (this.store.state.featureSet.validationFieldLayout) {
					// If there are field layout details already, only set classes for offsets/widths
					if (this.fieldLayoutDetails.length === 0) {
						this.getDocumentClassFieldGroupLayouts();
					} else {
						fieldGroups.forEach((fieldGroup) => {
							this.checkFieldsInGroup(fieldGroup.id!)
						})
					}
				} else {
					this.checkSameLineFieldsInGroup();
				}

				this.currentErrorField = null;
				return this.validateDocument();
			})
			.then(validationResult => {
				this.blocked.buttons.save = !validationResult;
			})
			.catch(reason => {
				// check status - go to ErrorPage-Component
				if (reason.status === 401) {
					router.push({ name: 'ErrorPage', params: { reason: 'notAccess' }, query: { status: reason.status }});
				} else if (reason.status === 403) {
					router.push({ name: 'ErrorPage', params: { reason: 'forbidden' }, query: { status: reason.status }});
				} else if (this.allDocumentClasses.filter(docClass => docClass.id !== this.documentClassId)) {
					this.dialogs.errorDocument.display = true;
				} else {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
				}
			})
			.finally(()=> {
				this.loaded = true;
				this.viewerShouldObserveResizing = true;
				this.blocked.buttons.suspend = this.blocked.buttons.delete = this.blocked.buttons.menu = false;

				// Always scroll up on load
				this.viewerMarkedRegions = [{page: 1, x0: 1, x1: 1, y0: 1, y1: 1}];

				// Set everything to read only, if the document is blocked
				if (this.dialogs.lockedDocument.locked || this.readonly) {
					this.setReadOnlyMode();
				}
			})
	}

	/** Get all error messages of fields */
	getAllErrorMessages() {
		const allErrorMessagesHead = this.documentFields.filter((field: DocumentField) => (field.value?.state?.toLowerCase() === 'error' || field.value?.state?.toLowerCase() === 'forceapproval'));
		const allErrorMessagesPos = this.tables.filter(table => (table.state?.toLowerCase() === 'error' || table.state?.toLowerCase() === 'forceapproval'))
			.map(table => {
				const field: DocumentField = {
					description: table.description,
					value: {
						errorText: this.$t('Squeeze.Validation.General.ErrorLines') + " " + table.errorText,
					},
				}
				return field;
			});
		this.allErrorMessages.lengthOfAllErrors = allErrorMessagesHead.length + allErrorMessagesPos.length;

		// Get first error field if there is none selected
		if (!this.currentErrorField) {
			const firstErrorField = this.documentFields.find((field: DocumentField) => (field.value?.state?.toLowerCase() === 'error' || field.value?.state?.toLowerCase() === 'forceapproval'));
			if (firstErrorField) {
				this.currentErrorField = firstErrorField;
			}
		}

		this.allErrorMessages.fields = allErrorMessagesHead.concat(allErrorMessagesPos);
	}

	/** Get fieldGroup layouts from documentClass */
	getDocumentClassFieldGroupLayouts() {
		this.documentClassApi.getDocumentClassFieldGroupLayouts(this.documentClassId)
			.then(response => response.json().then ((fieldLayouts: FieldGroupLayout[]) => {
				fieldLayouts.forEach((layout: FieldGroupLayout) => {
					if (JSON.parse(layout.fieldLayout).length > 0) {
						this.fieldLayoutDetails.push({
							fieldGroupId: layout.fieldGroupId,
							fieldLayout: JSON.parse(layout.fieldLayout),
						});
					} else {
						// set default values for a field in layout
						let allFieldsOfGroup: any[] = [];
						const fieldGroupFields = this.documentClassFieldsByGroup.find(fieldGroup => fieldGroup.id === layout.fieldGroupId);

						if (fieldGroupFields) {
							allFieldsOfGroup = fieldGroupFields.fields!.map((field, fieldGroupIndex) => {
								const fieldWidget = {
									id: field.id,
									width: 12,
									row: fieldGroupIndex,
									offset: 0,
									type: 'field',
									fieldGroupId: layout.fieldGroupId,
									documentClassId: this.documentClassId,
								};
								return fieldWidget;
							})
						}

						this.fieldLayoutDetails.push({
							fieldGroupId: layout.fieldGroupId,
							fieldLayout: allFieldsOfGroup,
						});

						// check here if feature flag for layout is false (deactivated)
						//this.checkSameLineFieldsInGroup();
					}
					this.checkFieldsInGroup(layout.fieldGroupId);
				})
			}))
			.catch(response => {
				if (!response.json) {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + response);
					return;
				}
				throw response;
			})
			.catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/**
	 * Check field colSize
	 * @param fieldGroupId Id of the field group
	 */
	checkFieldsInGroup(fieldGroupId: number) {
		const currentGroup = this.documentClassFieldsByGroup.find(fieldGroup => fieldGroup.id === fieldGroupId);
		const currentFieldLayout = this.fieldLayoutDetails.find(fieldGroup => fieldGroup.fieldGroupId === fieldGroupId);
		if (currentGroup && currentFieldLayout) {
			const fieldDetails: any = currentFieldLayout.fieldLayout;

			currentGroup.fields!.forEach((field: FieldInSameLine) => {
				fieldDetails.find((detail: any) => {
					if (detail.id === field.id || detail.content === field.description) {
						field.colSize = 'p-col-' + detail.width;
					}
				})
			});

			this.fieldLayoutByRows(fieldGroupId);
		}
	}

	/**
	 * Field Layout by rows
	 * @param fieldGroupId Id of the field group
	 */
	fieldLayoutByRows(fieldGroupId: number) {
		const fieldLayout = this.fieldLayoutComplete.fieldLayout.find(layout => layout.fieldGroupId === fieldGroupId);
		if (!fieldLayout) {
			return;
		}

		const fieldDetails: any = fieldLayout.fieldLayout;
		const rows = fieldDetails.map((widget: any) => { return widget.row }).filter((value: any, index: number, self: any) => self.indexOf(value) === index);

		const filteredRows = rows.map((row: any) => { return fieldDetails.filter((widget: any) => widget.row === row)});
		this.fieldLayoutComplete.fieldLayoutRows.push({
			fieldGroupId: fieldGroupId,
			rows: filteredRows,
		});
		if (filteredRows) {
			filteredRows.forEach((rows: any) => {
				if (rows.length === 1) {
					if (rows[0].width && (rows[0].width + rows[0].offset < 12)) {
						rows[0].offsetSize = rows[0].offset;
					} else if (rows[0].width && rows[0].width !== 12 && (rows[0].width + rows[0].offset === 12)) {
						const offsetSize = 12 - rows[0].width;
						rows[0].offsetSize = offsetSize;
					}
				} else if (rows.length > 1) {
					let previousWidgetElement: GridStackFieldDetails;
					rows.forEach((widget: any, index: number) => {
						previousWidgetElement = rows[index -1];

						if (rows.length > 1 && widget.offset > 0 && previousWidgetElement && previousWidgetElement.width) {
							widget.offsetSize = widget.offset - previousWidgetElement.width;

							// calc field offset when more then two fields in a row
							if (previousWidgetElement && previousWidgetElement.offset && previousWidgetElement.width) {
								widget.offsetSize = widget.offset - (previousWidgetElement.offset + previousWidgetElement.width);
							}
						} else {
							widget.offsetSize = widget.offset;
						}
					})
				}
			})
		}
	}

	/** Check which fields are in a group, when field in same line */
	checkSameLineFieldsInGroup() {
		let line: number[] = [];
		this.documentClassFieldsByGroup.forEach((group: DocumentClassFieldGroup) => {
			group.fields?.forEach((field: FieldInSameLine, index: number) => {
				if (!field.hidden) {
					if(!field.sameLineAsPreviousField) {
						if(line.length > 0) {
							line.forEach((fieldIndex: number) => {
								const currentField: FieldInSameLine = group.fields![fieldIndex];
								if(currentField) {
									currentField.colSize = this.setColSize(line);
									group.fields![fieldIndex] = currentField;
								}
							})
							line = [];
							line.push(index);
						} else {
							line.push(index);
						}
					} else {
						if(line.length === 3) {
							line.forEach((fieldIndex: number) => {
								const currentField: FieldInSameLine = group.fields![fieldIndex];
								currentField.colSize = 'p-col-4';
								group.fields![fieldIndex] = currentField;
							})
							line = [];
							line.push(index);
						} else {
							line.push(index);

							// check if is the last field of group, then set colSize
							if(group.fields?.length === index +1) {
								this.setColSizeOfCurrentField(line, group);
								line = [];
							}
						}
					}
				} else if (field.hidden && group.fields!.length === index +1) {
					// check if is the last field of group hidden, then set colSize
					this.setColSizeOfCurrentField(line, group);
					line = [];
				}
			})
		})
	}

	/**
	 * Set the colSize of current field of field group
	 * @param line Array with index of fields
	 * @param fieldGroup Current field group
	 */
	setColSizeOfCurrentField(line: number[], fieldGroup: DocumentClassFieldGroup) {
		line.forEach((fieldIndex: number) => {
			const currentField: FieldInSameLine = fieldGroup.fields![fieldIndex];
			if(currentField) {
				currentField.colSize = this.setColSize(line);
				fieldGroup.fields![fieldIndex] = currentField;
			}
		})
	}

	/**
	 * Get the colSize of fields when in same line as previous field
	 * @param line Array with index of fields
	 */
	setColSize(line: number[]) {
		let colSize: string = 'p-col-12';
		switch (line.length) {
		case 2: colSize = 'p-col-6'; break;
		case 3: colSize = 'p-col-4'; break;
		default: break;
		}

		return colSize;
	}

	sortFieldsByOrder(fieldA: DocumentField, fieldB: DocumentField) {
		if(fieldA && fieldB) {
			if((fieldA.sortOrder && fieldB.sortOrder) && (fieldA.sortOrder < fieldB.sortOrder)) {
				return -1;
			}
			if((fieldA.sortOrder && fieldB.sortOrder) && (fieldA.sortOrder > fieldB.sortOrder)) {
				return 1;
			}
		}
		return 0;
	}

	/** Deletes a document */
	async deleteDocument(comment: string) {
		this.blocked.buttons.delete = true;
		const nextDocumentId = await this.getNextDocument(true);
		this.documentApi.deleteDocumentById(this.documentId, comment)
			.then(async response => {
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Success'));
				this.handleNextDocumentId(nextDocumentId);
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Validation.Dialogs.DeleteDocument.Error') + "\n" + reason);
			})
			.finally(()=> {
				this.dialogs.deleteDocument.display = this.blocked.buttons.delete = false;
			})
	}

	/** Suspends a document */
	async suspendDocument(comment: string) {
		this.blocked.buttons.suspend = true;

		this.buildValidationRequest();
		const nextDocumentId = await this.getNextDocument(true);
		this.documentApi.saveAndSuspendDocument(this.documentId, comment, this.validationRequest)
			.then(async response => {
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Dialogs.SuspendDocument.Success'));
				this.handleNextDocumentId(nextDocumentId);
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Validation.Dialogs.SuspendDocument.Error') + "\n" + reason);
			})
			.finally(()=> {
				this.dialogs.suspendDocument.display = this.blocked.buttons.suspend = false;
			})
	}

	/** Validate document fields (trigger by field value change and at save event) */
	async validateDocument(): Promise<boolean> {
		let isValidHead = true;
		let isValidTable = true;
		this.blocked.buttons.save = true;

		if (this.readonly) {
			return (isValidHead && isValidTable);
		}

		this.buildValidationRequest();

		try {
			const validationResponse = await this.singlePromiseValidation.execute(this.documentApi.validateDocument(this.documentId, this.validationRequest));
			const validationFields = validationResponse.fields!;
			this.tables = validationResponse.tables;

			isValidHead = this.handleValidationFieldResponse(validationFields);
			isValidTable = this.handleValidationTableResponse(this.tables);

			if (isValidHead && isValidTable && !this.isReadOnlyMode) {
				this.blocked.buttons.save = false;
			}

			this.getAllErrorMessages();

			// If there is no table splitter, check if the active table has lines. If it has, always show
			if (!this.tableSplitterSetAfterValidation) {
				const table = this.tables.find(currTable => currTable.id === this.activeTableId);
				if (table) {
					this.checkTableBehaviour(table);
					this.getActiveTable();
					this.toggleResultList();
				}

				this.tableSplitterSetAfterValidation = true;
			}
		} catch (reason) {
			if (reason.json == null) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.Error'), String(reason));
			}
			else {
				const completeResponse = await reason.json() as ErrorDto;
				ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.Error'), this.$t('Squeeze.Validation.Dialogs.SaveDocument.Error') + "\n" + completeResponse.message);
			}

			isValidHead = isValidTable = false;
		}

		return (isValidHead && isValidTable);
	}

	/**
	 * Validates a document and then exports it
	 */
	async validateAndExportDocument() {
		this.blocked.buttons.save = true;

		this.buildValidationRequest();

		try {
			const nextDocumentId = await this.getNextDocument(true);
			await this.singlePromiseValidation.execute(this.documentApi.saveValidateAndExportDocument(this.documentId, this.validationRequest));
			ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Dialogs.SaveDocument.Success'));
			this.handleNextDocumentId(nextDocumentId);
		} catch (response) {
			// Check if actually a response object, show generic error if not
			if (response.json == null) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.Error'), String(response));
				return;
			}

			// Status 400 may be returned if document is invalid, saving failed or export failed
			if (response.status == 400) {
				const completeResponse = await response.json() as SaveValidateAndExportDocumentResponseDto;
				const validationResponseDto = completeResponse.validationResponse;
				// eslint-disable-next-line no-mixed-spaces-and-tabs
				// Document is not valid
				if (validationResponseDto.valid !== true) {
					this.handleValidationFieldResponse(validationResponseDto.fields!);
					ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.ErrorInvalidDocument'));
					return;
				}

				// Check if all exports are fine
				if (completeResponse.exportException && completeResponse.exportException.message) {
					this.showExportError = true;
					this.exportErrorMessage = completeResponse.exportException.message;
				}
				else if (completeResponse.documentSaveException && completeResponse.documentSaveException.message) {
					this.showExportError = true;
					this.exportErrorMessage = completeResponse.documentSaveException.message;
				}
				else {
					ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.Error'), this.$t('Squeeze.Validation.Dialogs.SaveDocument.Error'));
				}
				this.blocked.buttons.save = false;
			} else {
				const completeResponse = await response.json() as ErrorDto;
				ToastManager.showError(this.$toast, this.$t('Squeeze.Validation.Error'), this.$t('Squeeze.Validation.Dialogs.SaveDocument.Error') + "\n" + completeResponse.message);
				this.blocked.buttons.save = false;
			}
		}
	}

	private handleValidationFieldResponse(validationFields: ValidationFieldDto[]): boolean {
		let isValid = true;

		validationFields.forEach((field: ValidationFieldDto)  => {
			const index = this.documentFields.findIndex((documentField) => {
				return documentField.id === field.id;
			});

			if(field.state === "ERROR" || field.state === "FORCEAPPROVAL") {
				isValid = false;
			}

			const uiField = this.documentFields[index];
			switch (uiField.dataType?.toLowerCase()) {
			case "amount":
				uiField.value!.value = this.formatAmount(field.value.value as any);
				break;
			default:
				uiField.value!.value = field.value.value;
				break;
			}

			uiField.value!.boundingBox = field.value.boundingBox;
			if (field.mandatory != null) uiField.mandatory = field.mandatory;
			if (field.readonly != null) uiField.readonly = field.readonly;
			if (field.hidden != null) uiField.hidden = field.hidden;
			if (field.forceValidation != null) uiField.forceValidation = field.forceValidation;

			if (field.errorCode === -1 || field.errorCode == null) {
				// Unknown error code or no error code set: use plain errorText
				uiField.value!.errorText = field.errorText;
			} else {
				uiField.value!.errorText = this.$t("Squeeze.Validation.ErrorCode." + field.errorCode);
			}

			uiField.value!.state = field.state;
		});

		return isValid;
	}

	private handleValidationTableResponse(validationTables: DocumentTable[]): boolean {
		let isValidTable = true;

		// validation tableRow cell state
		validationTables.forEach((table) => {
			table.rows?.forEach((row) => {
				row.cells?.forEach((cell) => {
					if (!isValidTable) return false;
					isValidTable = cell.state === "OK" ||cell.state === "FORCEAPPROVAL";
				})
			})
		})

		/* validation table states
		validationTables.forEach((table) => {
			if (!isValidTable) return false;
			isValidTable = table.state === "OK" || table.state === "FORCEAPPROVAL";
		})
		*/

		return isValidTable;
	}

	buildValidationRequest(): void {
		this.validationRequest = {
			fields: [],
			tables: this.tables,
		};

		/**
		 * FIXME: Refactor ValidateFieldDto as well as DocumentField to match required params.
		 * Consider a low payload by passing necessary information only:
		 * - id
		 * - value
		 * - type
		 * Consider adding validation results for each field:
		 * - errortext
		 * - state
		 */
		this.documentFields.forEach(field => {
			let id = field.id;
			if (!id) id = 0;
			let fieldValue = field.value?.value;
			if (!fieldValue) fieldValue = "";
			if (field.dataType?.toLowerCase() === "amount") fieldValue = "" + fieldValue;
			let fieldState = field.state;
			if (!fieldState) fieldState = "";

			const validationField = {
				id: id,
				name: field.name,
				mandatory: field.mandatory,
				readonly: field.readonly,
				hidden: field.hidden,
				forceValidation: field.forceValidation,
				state: fieldState,
				errorText: "",
				value: {
					value: fieldValue,
					boundingBox: field.value?.boundingBox,
				},
			}
			this.validationRequest.fields?.push(validationField);
		});
	}

	/** Shows selected dialog and closes every other dialog */
	showDialog(selectedDialog: string) {
		for (const [dialog, configuration] of Object.entries(this.dialogs)) {
			configuration.display = (dialog.toLowerCase() === selectedDialog.toLowerCase());
		}
	}

	/** Marks a field in the viewer */
	markField(field: DocumentField) {
		this.activeDocumentField = field;
		this.activeTableCell = undefined;
		this.viewerClient!.markField(field.name as any);
	}

	/**
	 * Sets the Object for the currently active table
	 * @param tableId Id of the table to get
	 */
	setActiveTable(tableId: number) {
		this.showTableSplitter = true;
		if (!this.activeTableId) {
			this.activeTableId = tableId;
		} else if (this.activeTableId !== tableId) {
			this.activeTableId = tableId;
		} else {
			this.activeTableId = null;
			this.showTableSplitter = false;
		}
		const table = this.tables.find(table => table.id === this.activeTableId);

		this.activeTable = undefined;

		if (table) {
			this.activeTable = table;
		}
	}

	/** Gets the Object for the currently active table */
	getActiveTable() {
		const table = this.tables.find(table => table.id === this.activeTableId)

		this.activeTable = undefined;
		this.showTableSplitterGutter = false;

		if (table) {
			this.activeTable = table;
			this.showTableSplitterGutter = true;
		}
	}

	/** Emitted after a mail has been send successfully */
	afterSendSuccess() {
		this.dialogs.sendMail.display = false;
	}

	/** Is triggered when the Form changes */
	onChangeDocumentClassChangeForm(documentClass: number, trainDocument: boolean) {
		this.newDocumentClass = documentClass;
		this.dialogs.changeDocumentClass.trainDocument = trainDocument;
	}

	/** Saves the new Document Class to a document */
	async saveNewDocumentClass() {
		this.loaded = false;
		this.dialogs.changeDocumentClass.loading = true;
		const nextDocumentId = await this.getNextDocument(true);
		this.documentApi.changeDocumentClass(this.documentId, this.newDocumentClass, this.dialogs.changeDocumentClass.trainDocument)
			.then(async () => {
				this.loaded = true;
				this.dialogs.changeDocumentClass.display = false;
				this.handleNextDocumentId(nextDocumentId);
			})
			.catch(response => response.json().then ((err: any) => {
				this.loaded = true;
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => this.dialogs.changeDocumentClass.loading = false)
	}

	/** Extracts the Document again */
	async extractDocument() {
		this.loaded = false;
		const nextDocumentId = await this.getNextDocument(true);
		this.documentApi.extractDocument(this.documentId)
			.then(async () => {
				this.loaded = true;
				this.handleNextDocumentId(nextDocumentId);
			})
			.catch(response => response.json().then ((err: any) => {
				this.loaded = true;
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/**
	 * Mostly used to close this validation after validating, deleting or suspending.
	 * */
	goToNextQueueEntry() {
		// TODO: Currently the view will change to the Validation List of the Document Class, change to next document of queue
		// todo: unlock document? Or is it handled automatically via the route change?
		this.goBackToValidationList();
	}

	goBackToQueueList() {
		router.push({
			name: 'QueueEntryView',
			params: { stepName: this.$route.query.target as any},
			query: {
				page: this.$route.query.page as any,
			}});
	}

	/** Change the View to the Overview */
	goBackToValidationList() {
		// if the component was opened from the QueueEntryView, go back there
		if (this.$route.query.parent && this.$route.query.parent === 'QueueList' && this.$route.query.target) {
			this.goBackToQueueList();
			return;
		}

		let routeName = "DocumentListValidation";
		let currentSearch = this.searchRequest;
		if (Object.keys(this.searchRequest).length === 0 && Object.keys(this.currentSearchRequest).length > 0) {
			currentSearch = this.currentSearchRequest;
		}

		// set default search when validation open via link
		if (Object.keys(currentSearch).length === 0 &&  this.documentWorkflowContext.step && this.documentWorkflowContext.step.toLowerCase() === 'validation') {
			currentSearch.workflowContextFilters = [
				{
					fieldName: "queueStep",
					comp: "eq",
					searchValue: "validation",
					fieldType: "text",
				},
			]
		} else if (Object.keys(currentSearch).length === 0) {
			// When there is no search and the step is not validation, go to DocumentList
			routeName = "DocumentList";
		}

		// If there is a filter and one of the filters is from validation, go to DocumentListValidationWithSearch, otherwise go to DocumentList
		if (Object.keys(currentSearch).length > 0) {
			if (currentSearch.workflowContextFilters?.find(filter => filter.searchValue === "validation" && filter.fieldName === "queueStep")) {
				routeName = "DocumentListValidationWithSearch";
			} else {
				routeName = "DocumentList";
			}
		}

		router.replace({name: routeName, params: {
			documentClassId: this.$route.params.documentClassId,
			searchRequest: JSON.stringify(currentSearch),
			tableSortStart: JSON.stringify(this.tableSortStart),
			pagination: JSON.stringify(this.pagination),
		}});
	}

	/**
	 * Navigates to another document.
	 * Tries to unlock the current document before starting the navigation.
	 */
	gotoDocument(id: number, replace: boolean = true) {
		// Todo: Somehow make sure that if the next route element does not find the document (404), the fallback is the validation list
		const route: RouteLocationRaw = {name: "ValidateEntry", params: {documentClassId: this.documentClassId, documentId: id,
			searchRequest: JSON.stringify(this.searchRequest), pagination: JSON.stringify(this.pagination), tableSortStart: JSON.stringify(this.tableSortStart)}};

		// Reset Training-Marks
		this.viewerMarkedRegions = [];

		router.push(route);
	}

	/** Encode query element for router */
	encodeFilterQuery() {
		const query: {[key: string]: string} = {};
		if(this.searchRequest.workflowContextFilters && this.searchRequest.workflowContextFilters.length > 0) {
			query.wf = encodeURI(JSON.stringify(this.searchRequest.workflowContextFilters));
		}

		if(this.searchRequest.fieldFilters && this.searchRequest.fieldFilters.length > 0) {
			query.f = encodeURI(JSON.stringify(this.searchRequest.fieldFilters));
		}

		return query;
	}

	/**
	 * Browses to the previous document
	 */
	async browseLeft() {
		try {
			const prevDocument = await this.validationApi.browseValidation(this.documentId, this.documentClassId, "previous", this.searchRequest, this.tableSortStart);

			if (!prevDocument) {
				this.goBackToValidationList();
				return;
			}

			this.gotoDocument(prevDocument);
		}
		catch(response) {
			// Check if response is json, show generic error if not
			if (response.json != null) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(response.statusText));
				return;
			}

			const completeResponse = await response.json() as ErrorDto;
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(completeResponse.message));
		}
	}

	async goToNextDocument(getNextToValidate: boolean|undefined = undefined) {
		try {
			const nextDocument = await this.validationApi.browseValidation(this.documentId, this.documentClassId, "next", this.searchRequest, this.tableSortStart, getNextToValidate);

			if (!nextDocument) {
				this.pagination.page = 0;
				this.goBackToValidationList();
				return;
			}
			this.gotoDocument(Number(nextDocument));
		}
		catch(response) {
			// Check if response is json, show generic error if not
			if (response.json != null) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(response.statusText));
				return;
			}

			const completeResponse = await response.json() as ErrorDto;
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(completeResponse.message));
		}
	}

	/**
	 * Browses to the next document
	 */
	async getNextDocument(getNextToValidate: boolean|undefined = undefined): Promise<number | undefined> {
		try {
			const nextDocument = await this.validationApi.browseValidation(this.documentId, this.documentClassId, "next", this.searchRequest, this.tableSortStart, getNextToValidate);

			if (!nextDocument) {
				return;
			}

			return (Number(nextDocument))
		}
		catch(response) {
			// Check if response is json, show generic error if not
			if (response.json != null) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(response.statusText));
				return;
			}

			const completeResponse = await response.json() as ErrorDto;
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(completeResponse.message));
		}
	}

	/**
	 * This function handles the nextDocument id that is returned by {@link getNextDocument}. If next document id is
	 * undefined this functions routes the user back to validation list, otherwise to the next document.
	 * @param nextDocumentId
	 */
	handleNextDocumentId(nextDocumentId: number | undefined): undefined | void
	{
		if (nextDocumentId) {
			this.gotoDocument(nextDocumentId);
		} else {
			this.pagination.page = 0;
			this.goBackToValidationList();
			return;
		}
	}


	/**
	 * Gets the Index within a list from the current document
	 * @param documentId
	 * @private
	 */
	private async getIndexOfDocument(documentId: number): Promise<number|null> {
		// if there is no search, that means the document has been opened directly and the index does not matter
		if (Object.keys(this.searchRequest).length === 0) {
			return null;
		}

		// Only load search-elements if there are none yet
		if (!this.searchElements) {
			const result = await this.documentApi.searchDocuments(this.documentClassId, this.searchRequest, 'index', this.pagination.page, this.pagination.pageSize, this.tableSortStart);
			this.searchElements = result.elements as Document[];
		}

		if (!this.searchElements) {
			return null;
		}

		const indexOfDocument = this.searchElements.findIndex(element => element.id === documentId);

		// If document is no part of the return, go back to list
		if (indexOfDocument === -1) {
			return null;
		}

		return indexOfDocument;
	}


	/**
	 * Gets the id of the next document.
	 * @param documentId Id of the current document
	 **/
	async getNextDocumentId(documentId: number): Promise<number|null> {
		const indexOfDocument = await this.getIndexOfDocument(documentId);

		// only checking indexOfDocument won't work here, because 0 is a valid value for the first document in the list
		if (indexOfDocument == null) {
			return null;
		}

		// Get next element, if it's available
		if (this.searchElements && this.searchElements[indexOfDocument + 1]) {
			return this.searchElements[indexOfDocument + 1].id!;
		}

		// If the current element is the last, then check if there is next site and take the first element from there
		if (indexOfDocument === (this.searchElements!.length - 1)) {
			this.pagination.page = this.pagination!.page! + 1;
			const resultNextSite = await this.documentApi.searchDocuments(this.documentClassId, this.searchRequest, 'index', this.pagination.page, this.pagination.pageSize, this.tableSortStart);
			this.searchElements = resultNextSite.elements as Document[];

			// Get first document of next site and return it
			if (resultNextSite && this.searchElements[0]) {
				return this.searchElements[0].id!;
			}
		}

		return null;
	}

	/**
	 * Gets the id of the previous document.
	 * @param documentId Id of the current document
	 **/
	async getPrevDocumentId(documentId: number): Promise<number|null> {
		const indexOfDocument = await this.getIndexOfDocument(documentId);

		// only checking indexOfDocument won't work here, because 0 is a valid value for the first document in the list
		if (indexOfDocument == null) {
			return null;
		}

		// If the current element is the first element and the page is the first, go back to list
		if (indexOfDocument === 0 && this.pagination.page === 0) {
			return null;
		}

		// Take previous element, if available
		if (this.searchElements && this.searchElements[indexOfDocument - 1]) {
			return this.searchElements[indexOfDocument - 1].id!;
		}

		// If the current element is the first, try to get to the previous page
		if (indexOfDocument === 0) {
			this.pagination.page = this.pagination!.page! - 1;
			const resultNextSite = await this.documentApi.searchDocuments(this.documentClassId, this.searchRequest, 'index', this.pagination.page, this.pagination.pageSize, this.tableSortStart);
			this.searchElements = resultNextSite.elements as Document[];

			// Get last document of next previous site and return it
			if (resultNextSite && this.searchElements[this.searchElements.length -1]) {
				return this.searchElements[this.searchElements.length -1].id!;
			}
		}

		return null;
	}

	/**
	 * Triggered when a Head Field is focused
	 * @param field
	 */
	markFieldByRegion(field: DocumentField) {
		this.currentErrorField = field;
		this.activeTableCell = undefined;
		this.activeDocumentField = field;

		if (field.value && field.value.boundingBox) {
			this.viewerMarkedRegions = [field.value.boundingBox];
		}
		if (field.value && field.value.boundingBox && this.viewerClient) {
			this.viewerClient.markRegion(field.value.boundingBox);
		}
	}

	/**
	 * Triggered when a Table Cell is focused
	 * @param cell
	 * @param row
	 * @param index
	 */
	onFocusTableCell(cell: DocumentTableCell, row: DocumentTableRow, index: number, field: DocumentField) {
		//console.log(cell, row, index);
		this.currentErrorField = field;
		this.activeTableCell = cell;
		this.activeTableRowIndex = index;
		this.activeDocumentField = undefined;

		if (row.value && row.value.boundingBox) {
			this.viewerMarkedRegions = [row.value.boundingBox];
		}
		if (row.value && row.value.boundingBox && this.viewerClient) {
			this.viewerClient.markRegion(row.value.boundingBox);
		}
	}

	/** Unlock viewed document */
	async unlockViewedDocument() {
		await this.documentApi.unlockDocument(this.documentId)
			.then(() => {
				this.reloadData()
			})
			.catch((err: { message: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			})
			.finally(() => {
				this.dialogs.lockedDocument.display = false;
				this.blocked.buttons.training = false;
				this.blocked.buttons.testing = false;
				this.blocked.buttons.attachment = false;
				this.mode = 'edit';
				this.isReadOnlyMode = false;
				this.hideTableButtons = false;
			})
	}

	/**
	 * Unlocks the current document
	 * @param ignoreError If true no error will be displayed if unlocking fails
	 */
	async unlockDocument(ignoreError = false) {
		// Only unlock locked documents if it's locked by the current user
		if (!this.dialogs.lockedDocument.locked && this.lockedByCurrentUser) {
			try {
				await this.documentApi.unlockDocument(this.documentId);
			} catch (err: unknown) {
				if (!ignoreError) {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err);
				}
			}
		}
	}

	lockDocument() {
		this.documentApi.lockDocument(this.documentId)
			.then(() => {
				this.lockedByCurrentUser = true;
			})
			.catch(response => response.json().then((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/** Show or disabled toolbarButton-Text */
	async showToolbarButtonText() {
		this.disableViewerOnResizing();
		/** Check the window size at first load to set the toolbarButtonText to display none */
		if (window.innerWidth <= 1835) {
			this.toolbarButtonText = false;
		}
		this.toolbarButtonText = window.matchMedia('(min-width: 1880px)').matches;

		// disable and enable the viewer by resizing, so that the splitter is dynamic
		await this.$nextTick();
		this.enableViewerAfterResizing();
	}

	/** Triggered when the "read only"-button in the Locked Dialog is clicked */
	onClickDocumentReadOnly() {
		this.dialogs.lockedDocument.display = false;
		this.mode = "readonly";
		this.isReadOnlyMode = true;
		this.hideTableButtons = true;
	}

	/** Sets the whole document to read only */
	setReadOnlyMode() {
		this.isReadOnlyMode = true;
		this.hideTableButtons = true;

		this.blocked.buttons.save = true;
		this.blocked.buttons.delete = true;
		this.blocked.buttons.suspend = true;

		this.blocked.buttons.training = true;
		this.blocked.buttons.testing = true;
		this.blocked.buttons.attachment = true;

		// Allow training / locator testing even for processed documents
		if (this.currentDocument?.workflowContext?.step === 'Backup') {
			this.blocked.buttons.training = false;
			this.blocked.buttons.testing = false;
		}

		this.initToolbarOptions();
		this.showToolbarButtonText();
	}

	/** Init the toolbar options. Otherwise the changes of the disabled attribute might not be realized */
	initToolbarOptions() {
		this.toolbarOptions = [
			{
				label: this.$t('Squeeze.Validation.Extraction.RecreateResult'),
				icon: 'mdi mdi-file-restore-outline',
				disabled: this.blocked.buttons.extract,
				command: () => {
					this.extractDocument();
				},
			},
			{
				label: this.$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.Change'),
				icon: 'mdi mdi-file-document-edit-outline',
				disabled: this.blocked.buttons.changeDocumentClass,
				command: () => {
					this.showDialog("changeDocumentClass");
				},
			},
			{
				label: this.$t('Squeeze.Validation.Email.SendMail'),
				icon: 'mdi mdi-email-send-outline',
				disabled: false,
				command: () => {
					this.showDialog("sendMail");
				},
			},
			{
				label: this.$t('Squeeze.Validation.Dialogs.SplitDocument.SplitCommand'),
				icon: 'mdi mdi-file-compare',
				disabled: this.blocked.buttons.split,
				command: () => {
					this.showSplitDocument = true;
					this.loaded = false;
					this.hideTableButtons = true;
					this.hideValidationButtons = true;
					this.showTableSplitter = false;
				},
			},
			{
				label: this.$t('Squeeze.Training.FieldTraining'),
				icon: 'mdi mdi-form-textbox',
				disabled: this.blocked.buttons.training,
				command: () => {
					this.showHeadTraining = true;
					this.loaded = false;
					this.hideTableButtons = true;
					this.hideValidationButtons = true;
					this.showTableSplitter = false;
					this.showPositionTraining = false;
					this.showOcrResults = false;
					this.showLocatorTest = false;

					// reset markedRegions in viewer
					this.viewerMarkedRegions = [];
				},
			},
			{
				label: this.$t('Squeeze.Training.PositionTraining'),
				icon: 'mdi mdi-table',
				disabled: this.blocked.buttons.training,
				command: () => {
					this.showPositionTraining = true;
					this.loaded = false;
					this.hideTableButtons = true;
					this.hideValidationButtons = true;
					this.showTableSplitter = false;
					this.showHeadTraining = false;
					this.showOcrResults = false;
					this.showLocatorTest = false;
				},
			},
			{
				label: this.$t('Squeeze.Attachments.ZIPDownlaod'),
				icon: 'mdi mdi-folder-download-outline',
				command: () => {
					this.downloadAttachment();
				},
			},
			{
				label: this.$t('Squeeze.DocumentClasses.OCRResults'),
				icon: 'mdi mdi-ocr',
				command: () => {
					this.showOcrResults = true;
					this.loaded = false;
					this.hideTableButtons = true;
					this.hideValidationButtons = true;
					this.showTableSplitter = false;
					this.showHeadTraining = false;
					this.showPositionTraining = false;
					this.showLocatorTest = false;

					// reset markedRegions in viewer
					this.viewerMarkedRegions = [];
				},
			},
			{
				label: this.$t('Squeeze.Locators.Test'),
				icon: 'mdi mdi-folder-text-outline',
				disabled: this.blocked.buttons.testing,
				command: () => {
					this.showLocatorTest = true;
					this.loaded = false;
					this.hideTableButtons = true;
					this.hideValidationButtons = true;
					this.showTableSplitter = false;
					this.showHeadTraining = false;
					this.showPositionTraining = false;
					this.showOcrResults = false;

					// reset markedRegions in viewer
					this.viewerMarkedRegions = [];
				},
			},
			{
				label: this.$t('Squeeze.Validation.General.AddAttachment'),
				icon: 'mdi mdi-cloud-upload-outline',
				disabled: this.blocked.buttons.attachment,
				command: () => {
					this.showUpload = true;
				},
			},
		]

		this.toolbarOptions.push(
			{
				label: this.$t('Squeeze.Validation.Help'),
				icon: 'mdi mdi-comment-question-outline',
				command: () => {
					this.showHotKeyList = true;
				},
			}
		)

		if (this.store.state.scopes.sqzAdmin && this.store.state.featureSet.documentLog) {
			this.toolbarOptions.push(
				{
					label: this.$t('Squeeze.Validation.Log.Log'),
					icon: 'mdi mdi-script-text-outline',
					command: () => {
						this.showLog = true;
					},
				}
			)
		}

		if (this.customActions && this.customActions.length) {
			// get custom action as menuItem object
			const allActions: CustomAction[] = [];
			this.customActions.map((action: CustomAction) => {
				allActions.push({
					label: action.description,
					icon: 'mdi mdi-square-small',
					command: () => { this.executeCustomValidationAction(action.id as string) },
				})
			})

			this.toolbarOptions.push(
				{
					label: this.$t('Squeeze.CustomAction.Actions'),
					items: allActions,
				}
			)
		}
	}

	/** Download the current Attachment in Validation (as ZIP file) */
	downloadAttachment() {
		const downloadURL = ClientManager.getInstance().buildDocumentDownloadUrl(this.documentId);
		window.open(downloadURL);
	}

	/**
	 * Reopens the Validation show (Shows the Buttons, tables and fields, hides everything else)
	 */
	reopenValidationView() {
		this.showSplitDocument = false;
		this.hideTableButtons = false;
		this.hideValidationButtons = false;
		this.showHeadTraining = false;
		this.showPositionTraining = false;
		this.showLocatorTest = false;
		this.loaded = true;
		this.showTableSplitter = true;
		this.showOcrResults = false;

		// reset markedRegions in viewer
		this.viewerMarkedRegions = [];
	}

	/**
	 * Is clicking the options button, then show toolbarOptions in overlayPanel
	 * @param event
	 */
	toggleToolBarOptions(event: any) {
		this.setToolbarOptionsInReadOnlyMode();
		const overlayPanel: any = this.$refs.menu;
		overlayPanel.toggle(event);
	}

	toggleErrorMessages(event: any) {
		const overlayPanel: any = this.$refs.opBadge;
		overlayPanel.toggle(event);
	}

	/** Set ToolbarOptions in read only mode, when headTraining|positionTraining|ocrResults|locatorTesting is shown */
	setToolbarOptionsInReadOnlyMode() {
		if(this.hideValidationButtons) {
			this.blocked.buttons.extract = true;
			this.blocked.buttons.changeDocumentClass = true;
			this.blocked.buttons.split = true;
		} else {
			this.blocked.buttons.extract = false;
			this.blocked.buttons.changeDocumentClass = false;
			this.blocked.buttons.split = !this.store.state.featureSet.uiAllowDocumentSplit;
		}

		if (this.readonly) {
			this.blocked.buttons.extract = true;
			this.blocked.buttons.changeDocumentClass = true;
			this.blocked.buttons.split = true;
		}

		this.initToolbarOptions();
	}

	/**
	 * Triggered when a page on the Document Split Component is clicked
	 * @param page Page that is triggered
	 */
	onSplitImageClick(page: number) {
		// reset markedRegions in viewer
		this.viewerMarkedRegions = [];

		if (this.NEW_SQUEEZE_VIEWER) {
			this.viewerMarkedRegions = [{page: page, x0: 1, x1: 1, y0: 1, y1: 1}];
		} else {
			this.viewerClient?.goToPage(page);
		}
	}

	/**
	 * Is triggered when there are no more pages to split
	 */
	async onEmptyList() {
		const nextDocumentId = await this.getNextDocument(true);
		this.documentApi.deleteDocumentById(this.documentId, "SplitDone")
			.then(async () => {
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Dialogs.SplitDocument.AllPagesSplit'));
				this.handleNextDocumentId(nextDocumentId);
			})
			.catch(response => response.json().then ((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/**
	 * Triggered when a Table Cell is focused
	 * @param fieldName
	 */
	onFocusFieldTraining(fieldName: string) {
		this.activeFieldTraining = fieldName;
	}

	/**
	 * Triggered when an Autocomplete-Item is hovered and it has an DocumentField-Value
	 * @param fieldValue
	 * @param field
	 */
	onHoverItemAutocomplete(fieldValue: DocumentFieldValue) {
		if (fieldValue.boundingBox) {
			this.viewerMarkedRegions = [fieldValue.boundingBox] as BoundingBox[];
			this.viewerClient?.markRegion(fieldValue.boundingBox);
		}
	}

	/**
	 * Triggered when a Table Cell is focused
	 * @param markRegions
	 */
	onMarkRegion(markRegions: any) {
		if (Array.isArray(markRegions)) {
			this.viewerMarkedRegions = markRegions as BoundingBox[];
			this.viewerClient?.markRegions(markRegions);
		} else {
			// This is a special case for the position training
			markRegions.page = 0;
			this.viewerMarkedRegions = [markRegions] as BoundingBox[];
			this.viewerClient?.markRegion(markRegions);
		}
	}

	/**
	 * Triggered when a Table Row is clicked
	 * @param markLine
	 */
	onMarkLines(markLine: any) {
		this.viewerMarkedRegions = [markLine.data.boundingBox];
		this.viewerClient?.markRegion(markLine.data.boundingBox);
	}

	/**
	 * Triggered when a Table Cell is clicked
	 * @param markWord
	 */
	onMarkWord(markWord: any) {
		this.viewerMarkedRegions = [markWord.data.boundingBox];
		this.viewerClient?.markRegion(markWord.data.boundingBox);
	}

	/**
	 * Triggered when a field of position training is focused
	 * @param fieldName
	 */
	onFocusFieldOfPositionTraining(fieldName: string) {
		this.activePositionTraining = fieldName;
		if (this.activePositionTraining === 'columnRegion') {
			this.viewerClient!.activateColumnMarking();
		} else {
			this.viewerClient!.deactivateColumnMarking();
		}
	}

	/** Get TrainingKey of DocumentClass */
	getDocumentClassTrainingKey() {
		this.documentClassApi.getDocumentClassTrainingKey(this.documentClassId)
			.then(trainingKey => {
				const trainingKeyId = trainingKey.ids![0];
				if (trainingKey.ids?.length === 0) {
					this.blocked.buttons.training = true;
				}
				if (trainingKey.ids!.length !== 1 && trainingKey.ids!.length > 1) {
					this.blocked.buttons.training = true;
					throw this.$t('Squeeze.Training.MultiTrainingKey');
				}
				this.documentFields.forEach(field => {
					if (field.id === trainingKeyId) {
						this.trainingKeyField = field;
					}
				})
				if (this.trainingKeyField === null && trainingKey.ids!.length === 1) {
					this.blocked.buttons.training = true;
					throw this.$t('Squeeze.Training.TrainingKeyNotFound');
				}
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			})
	}

	/** Formats an amount to locale string (Formats are only defined in the backend) */
	formatAmount(value: string) {
		return value;
		/*value = value.replace(/[^0-9.,-]/g, "");

		if(value.indexOf(",") != -1) {
			value = value.replaceAll(".", "").replace(",", ".");
		}

		if(value.length > 0) {
			return parseFloat(value).toLocaleString(i18n.global.locale.toLowerCase() + '-' + i18n.global.locale.toUpperCase(), {minimumFractionDigits: 2});
		} else {
			return "0,00";
		}*/
	}

	/**
	 * Get all headRefField of a fieldGroup
	 * @param headRefField
	 */
	async allHeadRefFields(headRefField: any) {
		// Filter all visible Fields from Array
		const allVisibleFields = headRefField.filter((field: any) => field.field.hidden === false);
		this.headRefFieldElements.push(allVisibleFields);

		// get focus on first field
		await this.focusOnFirstField();
	}

	/**
	 * Get all tableRefCells of a table
	 * @param tableRefCells
	 */
	allTableRefCells(tableRefCells: any) {
		this.allTableRefElements.push(tableRefCells);
	}

	/**
	 * Triggered when Enter/Tab-Keydown in first or last fieldGroup field
	 * @param currentFieldGroup
	 * @param isNextField
	 */
	async enterDocumentGroup(currentFieldGroup: number, isNextField: boolean) {
		const currentGroupIndex = this.documentClassFieldsByGroup.findIndex(fieldGroup => fieldGroup.id === currentFieldGroup);

		if(currentGroupIndex !== -1 && !isNextField) {
			// Triggered when a field of headFieldGroup was the first element, then focused the last element in the previous headFieldGroup
			const prevGroupIndex = currentGroupIndex - 1;
			if(prevGroupIndex !== this.headRefFieldElements.length && prevGroupIndex >= 0) {
				this.activeGroupTab = prevGroupIndex;
				await nextTick();
				this.headRefFieldElements[prevGroupIndex][this.headRefFieldElements[prevGroupIndex].length -1].element.$el.focus();
			}
		} else if(currentGroupIndex !== this.documentClassFieldsByGroup.length && isNextField) {
			// Triggered when a field of headFieldGroup was the last element, then focused the next element in the next headFieldGroup
			const nextGroupIndex = currentGroupIndex + 1;
			if(nextGroupIndex !== this.headRefFieldElements.length) {
				this.activeGroupTab = nextGroupIndex;
				await nextTick();
				this.headRefFieldElements[nextGroupIndex][0].element.$el.focus();
			} else {
				// Triggered when last element of headFieldGroup is active, then focus the first cell in table
				if (this.allTableRefElements[0] && this.allTableRefElements[0][0] && this.allTableRefElements[0][0].element && this.allTableRefElements[0][0].element.$el) {
					this.allTableRefElements[0][0].element.$el.focus();

					if (this.allTableRefElements[0][0].element.$el.firstElementChild) {
						this.allTableRefElements[0][0].element.$el.firstElementChild.focus();
					}
				} else {
					// Triggered when last element of headFieldGroup is active and no more fields or table cells exist
					await this.validateDocument();
				}
			}
		}
	}

	/** Triggered when nextTableCell is undefined - focus last headField */
	focusLastHeadField() {
		const prevGroup = this.headRefFieldElements.slice(-1)[0];
		const prevElement = prevGroup.slice(-1)[0];
		const prevGroupIndex = this.headRefFieldElements.findIndex((group: any) => group === prevGroup);
		const prevElementIndex = prevGroup.findIndex((field: any) => field === prevElement);

		this.headRefFieldElements[prevGroupIndex][prevElementIndex].element.$el.focus();
	}

	/** Should the drag dialog be shown? */
	showDragDialog: boolean = false;

	triggerShowDragDialog(event: any) {
		event.preventDefault();

		// check first item of dataTransfer
		const item = event.dataTransfer.items[0];

		if (!item) {
			return;
		}

		if (item.kind === 'string') {
			return
		}

		if (!this.hideValidationButtons && !this.showUpload && !this.isUploadShown) {
			this.showDragDialog = true;
		}
	}

	onDragEnter() {
		this.showDragDialog = true;
	}

	onDragLeave() {
		this.showDragDialog = false;
	}

	onDrop(event: any) {
		event.stopPropagation();
		event.preventDefault();
		this.showDragDialog = false;

		const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
		this.showUpload = true;
		nextTick(() => {
			const uploadFiles: any[] = [];
			for (const file of files) {
				file.uploadFinished = false;
				file.error = false;
				file.errorText = "";
				file.loading = false;
				uploadFiles.push(file);
			}
			this.files = uploadFiles;
			this.fileUploader({files: uploadFiles});
		})
	}

	/**
	 * Is triggered when files are selected from the Component
	 * @param event File-Select event
	 */
	onSelectFiles(event: any) {
		this.files = event.files;
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";
	}

	/**
	 * Is triggered when the "clear" button is pressed in the Upload-Component
	 * @param event
	 */
	clearFiles(event: any) {
		this.uploadLabel = this.$t("Squeeze.General.Upload");
	}

	/**
	 * Is triggered when a single file is removed from upload
	 * @param event
	 */
	removeFile(event: any) {
		this.files = event.files;
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";
	}

	/** Uploads the files from the file-uploader */
	fileUploader(event: any) {
		this.files = event.files;

		this.progress = 0;
		// Calculate progress
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";

		// TODO: Limit the amount of uploads running in parallel (?)
		event.files
			.forEach((file: any, index: number) => {
				if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
					const idx = index;
					this.files[idx].error = false;
					this.files[idx].errorText = "";
					this.files[idx].loading = true;
					this.files = [...this.files];

					this.manualFileUpload(file)
						.then(data => {
							this.files[idx].uploadFinished = true;
						})
						.catch(err => {
							this.files[idx].error = true;
							this.files[idx].errorText = err.message;
						})
						.finally(() => {
							this.files[idx].loading = false;
							this.files = [...this.files];

							// Calculate progress
							const finished = this.files.filter(file => file.uploadFinished);
							this.progress = Math.round((finished.length * 100) / this.files.length);
							this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + finished.length + "/" + this.files.length + ")";

							if (this.NEW_SQUEEZE_VIEWER) {
								(this.$refs.SqueezeViewer as any).refreshAttachments();
							} else {
								// Reload iFrame to reload attachments in old viewer
								const iFrame = document.getElementById('SqueezeViewer') as any;
								if (iFrame instanceof HTMLIFrameElement && iFrame.contentWindow != null) {
									// get viewer url for reload
									const viewer = ClientManager.getInstance().viewer;
									const viewerUrl: string = viewer.basePath + '/Viewer.php?aktion=showQueueEntryDetails&sourceSystem=documents&xdocid=' + this.documentId;
									iFrame.contentWindow.location.replace(viewerUrl);
								}
							}
						});
				}
			})
	}

	/**
	 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
	 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
	 * @param file
	 * @returns Object with the id of the created document
	 */
	async manualFileUpload(file: File) {
		// Todo: Once the generated client works, make this function deprecated and use the client function:
		const body = new FormData();
		body.set("file", file);

		const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/documents/" + this.documentId + "/attachments", {
			method: "POST",
			body: body,
		});

		if (response.status !== 204) {
			const responseJSON = await response.json();
			throw new Error(responseJSON.message);
		}

		return true;
	}

	/**
	 * Checks the serverity of a message
	 */
	checkErrorSeverity(): string {
		if (!this.currentErrorField) {
			if (this.allErrorMessages.lengthOfAllErrors === 0) {
				return "success";
			}
		}

		if (this.allErrorMessages && this.currentErrorField && this.currentErrorField.value!.state?.toLowerCase() === 'forceapproval') {
			return "info"
		}

		if (this.allErrorMessages && this.currentErrorField && this.currentErrorField.value!.state?.toLowerCase() === 'ok') {
			return "success"
		}

		return "error";
	}

	/** Check table behaviour */
	checkTableBehaviour(table: DocumentTable) {
		switch(table.tableBehaviour) {
		case TableBehaviourEnum.Open: {
			this.showTableSplitter = true;
			break;
		}
		case TableBehaviourEnum.Closed: {
			this.showTableSplitter = false;
			break;
		}
		default: {
			if (table.rows?.length === 0) {
				this.showTableSplitter = false;
			} else {
				this.showTableSplitter = true;
			}
			break;
		}
		}
	}

	/** Triggered when hover on button to show the document type
	 * @param documentType
	 */
	onHoverDocumentType(documentType: string) {
		if (documentType === 'xrechnung') {
			return this.$t('Squeeze.DocumentClasses.DocumentTypeXRechnung');
		}

		if (documentType === 'zugferd') {
			return this.$t('Squeeze.DocumentClasses.DocumentTypeZUGFeRD');
		}
	}

	/**
	 * Set focus on the first field of a field group
	 * Triggered also when the field group (tab) is changed
	 */
	async focusOnFirstField() {
		await nextTick();
		const currentFgRefFieldElements = this.headRefFieldElements[this.activeGroupTab];
		// set focus on first InputField or DropdownField
		if (currentFgRefFieldElements && currentFgRefFieldElements[0] && !this.dialogs.lockedDocument.display) {
			if (currentFgRefFieldElements[0].element.dropdown || currentFgRefFieldElements[0].field.lookup.active) {
				// focus first field when field is a dropdown
				const button = currentFgRefFieldElements[0].element.$el.querySelector('.p-button.p-autocomplete-dropdown');
				const input = currentFgRefFieldElements[0].element.$el.querySelector('.p-autocomplete-input.p-inputtext');
				button.click();
				input.focus();
			} else {
				currentFgRefFieldElements[0].element.$el.focus();
			}
		}
	}

}
</script>
<style lang="scss" scoped>

.root-splitter {
	height: 100vh;
}

/* Class to disabled the splitter gutter of Table, when Table is undefined or hidden */
.disabled-splitter-gutter > ::v-deep(.p-splitter-gutter) {
	display: none;
}

.content {
	height: 100%;
	width: 100%;
	display: flex;
	overflow: hidden;
	flex-flow: column nowrap;
}

.content-error-messages {
	padding: 0.5rem 1rem 0.5rem 1rem;
}

.scroll-content {
	flex: 1 1 0;
	align-self: auto;
	height: 100%;
	overflow-y: auto;
}

.viewer {
	height: 100%;
	width: 100%;
}

::v-deep(.p-splitter-gutter-handle) {
	background: var(--dex-primary-color) !important;
	width: 5% !important;
	height: 1.875rem !important;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	position: sticky;
	cursor: pointer;
}

::v-deep(.p-splitter-panel-nested .p-splitter-gutter-handle) {
	width: 1.5rem !important;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	position: unset;
	cursor: col-resize;
}

::v-deep(.p-scrollpanel-content) {
	padding: 0 18px 20px 0;
}

/* width & height  */
::-webkit-scrollbar {
	width: 0.525rem;
	height: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--dex-primary-light-color);
	border-radius: 0.525rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--dex-primary-color);
}

@media only screen and (max-width: 920px) {
	.p-button.p-button-icon-only {
		width: 2rem;
	}
}

.p-button.p-button-icon-only {
	width: 2.5rem;
}

::v-deep(.p-message) {
	width: 100%;
	margin: 0 0 0.5rem 0;
}

::v-deep(.p-message .p-message-wrapper) {
	padding: 0 1rem 0 1rem;
}

::v-deep(.p-message .p-message-text) {
	width: 100%;
}

::v-deep(.p-message.p-message-error .p-message-icon) {
	display: none;
}

::v-deep(.p-message.p-message-success .p-message-icon) {
	display: none;
}

.horizontal-line {
	width: 100%;
	border: 0.063rem solid var(--surface-300);
	margin: 0;
}

.dragFiles {
	height: 100vh;
	width: 100%;
	text-align: center;
	border: 2px dashed #000;
	background: var(--dex-secondary-background-color);
	display: none;
	position: fixed; top: 0; left: 0;
	z-index: 9999999999;
	margin-top: 0px;
	margin-bottom: 0px;
}

.drag {
	display: block;
}

.dragContainer{
	position: relative;
}

.uploadDragImage {
	position: absolute;
	bottom: 10rem;
	font-size: 10rem;
}

::v-deep(.p-tabview-panel) {
	overflow-x: hidden;
}

.p-grid.p-jc-between.errorMessages {
	margin-right: 0rem;
	margin-left: 0rem;
	margin-top: 0rem;
}



</style>
