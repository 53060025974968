import {ClientManager} from "@/singletons/ClientManager";
import {FeatureSet, MigrationStatus, SystemInfoDto, User} from "@dex/squeeze-client-ts";
import { InjectionKey } from 'vue';
import { createStore, useStore, Store } from 'vuex';
import { ViewerSettings } from "@dex/squeeze-viewer";

interface ScopesSet {
	sqzAdminView: boolean;
	sqzAdmin: boolean;

	/** Document Items */
	sqzRequeue: boolean;
	sqzBatchClasses: boolean;
	sqzDocumentClasses: boolean;
	sqzLocators: boolean;
	sqzMasterDataEdit: boolean;
	sqzMasterDataManage: boolean;

	/** Imports Items */
	sqzMail: boolean;
	sqzFileSystem: boolean;

	/** UserManagement Items */
	sqzUsersManage: boolean;
	sqzRoleManage: boolean;
	sqzRoleHierarchy: boolean;

	/** System Items */
	sqzScriptsManage: boolean;
	sqzScriptsExecute: boolean;
	sqzMigrations: boolean;
	sqzSystemCheck: boolean;
	sqzReset: boolean;
	sqzJobsManage: boolean;
	sqzJobsRead: boolean;
	sqzSystem: boolean;
}

interface UserSettings  {
	viewSettings: {
		smallDistanceBetweenFields: boolean;
		fontSize: number;
		v2Viewer: boolean;
		viewerSettings: ViewerSettings;
	};
	tableSettings: string;
}

interface RootState {
	user: User | null;
	isAdminUser: boolean;
	isLoggedIn: boolean;
	featureSet: FeatureSet;
	errorMigrations: MigrationStatus[];
	userSettings: UserSettings;
	systemInformation: SystemInfoDto;
	scopes: ScopesSet;
}

export const squeezeStoreKey: InjectionKey<Store<RootState>> = Symbol()

const storeDefault = () => {
	return {
		user: null,
		isAdminUser: false,
		isLoggedIn: false,
		featureSet: {
			selfUpdate: false,
			v1Viewer: true,
			v2Viewer: false,
			masterDataTablePermissions: false,
			jobManagement: false,
			newsSidebar: false,
			documentLog: false,
			validationFieldLayout: false,
			savedDocumentSearches: false,
			translationAdministration: false,
			uiConfigExport: false,
			uiConfigImport: false,
			uiAllowDocumentUpload: false,
			uiAllowDocumentSplit: false,
		},
		errorMigrations: [],
		userSettings: {
			viewSettings: {
				smallDistanceBetweenFields: true,
				fontSize: 0.925,
				v2Viewer: true,
				viewerSettings: {
					highlightOptions: {
						drawColor: '#ffff00',
						draw: true,
						borderColor: '#ffff00',
						borderStyle: 'solid',
						border: true,
					},
					zoomOptions: {
						fullWidth: true,
						fullHeight: false,
						customZoom: false,
						customSlider: 1,
					},
				},
			},
			tableSettings: '',
		},
		systemInformation: {
			tenant: '',
			tenantName: '',
		},
		scopes: {
			sqzAdminView: false,
			sqzAdmin: false,
			sqzMasterDataEdit: false,
			sqzMasterDataManage: false,

			sqzRequeue: false,
			sqzBatchClasses: false,
			sqzDocumentClasses: false,
			sqzLocators: false,

			sqzMail: false,
			sqzFileSystem: false,

			sqzUsersManage: false,
			sqzRoleManage: false,
			sqzRoleHierarchy: false,

			sqzScriptsManage: false,
			sqzScriptsExecute: false,
			sqzMigrations: false,
			sqzSystemCheck: false,
			sqzReset: false,
			sqzJobsManage: false,
			sqzJobsRead: false,
			sqzSystem: false,
		},
	}
}

export const squeezeStore = createStore<RootState>({
	state() {
		return storeDefault();
	},
	mutations: {
		resetToDefault(state) {
			Object.assign(state, storeDefault());
		},
		setUserData(state, user: User) {
			state.user = user;

			const userRoles = user.roles;
			if(userRoles && userRoles.length > 0) {
				for(const role of userRoles) {
					if(role.id === 1) {
						state.isAdminUser = true;
					}
				}
			}

			const userScopes = user.scopes;
			if(userScopes) {
				// set all state scopes booleans to false
				state.scopes.sqzAdminView = false;
				state.scopes.sqzAdmin = false;
				state.scopes.sqzMasterDataEdit = false;
				state.scopes.sqzMasterDataManage = false;

				for (const key in userScopes) {
					const value = userScopes[key];
					switch(value) {
					case 'sqz_admin_view': {
						state.scopes.sqzAdminView = true;
						break;
					}
					case 'sqz_admin': {
						state.scopes.sqzAdmin = true;

						state.scopes.sqzBatchClasses = true;
						state.scopes.sqzDocumentClasses = true;
						state.scopes.sqzLocators = true;

						state.scopes.sqzMail = true;
						state.scopes.sqzFileSystem = true;

						state.scopes.sqzRoleHierarchy = true;

						state.scopes.sqzMigrations = true;
						state.scopes.sqzSystemCheck = true;
						state.scopes.sqzReset = true;
						state.scopes.sqzSystem = true;
						break;
					}
					case 'sqz_requeue': {
						state.scopes.sqzRequeue = true;
						break;
					}
					case 'sqz_masterdata_edit': {
						state.scopes.sqzMasterDataEdit = true;
						break;
					}
					case 'sqz_masterdata_manage': {
						state.scopes.sqzMasterDataManage = true;
						break;
					}
					case 'sqz_users_manage': {
						state.scopes.sqzUsersManage = true;
						break;
					}
					case 'sqz_roles_manage': {
						state.scopes.sqzRoleManage = true;
						break;
					}
					case 'sqz_scripts_execute': {
						state.scopes.sqzScriptsExecute = true;
						break;
					}
					case 'sqz_scripts_manage': {
						state.scopes.sqzScriptsManage = true;
						break;
					}
					case 'sqz_jobs_manage': {
						state.scopes.sqzJobsManage = true;
						break;
					}
					case 'sqz_jobs_read': {
						state.scopes.sqzJobsRead = true;
						break;
					}
					}
				}
			}
		},
		setFeatureSet(state, featureSet: FeatureSet) {
			state.featureSet = featureSet;
		},
		setMigrations(state, migrations: MigrationStatus[]) {
			state.errorMigrations = migrations;
		},
		setLogin(state, isLoggedIn: boolean) {
			state.isLoggedIn = isLoggedIn;
		},
		setUserSettings(state, userSettings: UserSettings) {
			// If there are no viewerSettings or if there are some settings missing in this object (which might be case after updating a system), initialize the object
			if (userSettings.viewSettings && (userSettings.viewSettings.fontSize === undefined || userSettings.viewSettings.v2Viewer === undefined || !userSettings.viewSettings.viewerSettings || !userSettings.viewSettings.viewerSettings.zoomOptions
			|| !userSettings.viewSettings.viewerSettings.highlightOptions)) {
				userSettings.viewSettings = {
					smallDistanceBetweenFields: true,
					fontSize: 0.925,
					v2Viewer: true,
					viewerSettings: {
						highlightOptions: {
							drawColor: '#ffff00', // yellow
							draw: true,
							borderColor: '#ffff00', // yellow
							borderStyle: 'solid',
							border: false,
						},
						zoomOptions: {
							fullWidth: true,
							fullHeight: false,
							customZoom: false,
							customSlider: 1,
						},
					},
				}
			}

			if (!state.featureSet.v2Viewer) {
				userSettings.viewSettings.v2Viewer = false;
			} else if (!state.featureSet.v1Viewer && state.featureSet.v2Viewer) {
				userSettings.viewSettings.v2Viewer = true;
			}

			// set global fontSize
			document.documentElement.style.setProperty("--global-font-size", userSettings.viewSettings.fontSize + 'rem');
			Object.assign(state.userSettings, userSettings);
		},
		setSystemInformation(state, systemInformation: SystemInfoDto) {
			state.systemInformation = systemInformation;
		},
	},
	actions: {
		resetStore({commit}) {
			commit('resetToDefault');
		},
		/** Fetches the user data and stores it */
		async fetchUserData({commit}) {
			const userApi = ClientManager.getInstance().squeeze.user;
			const userData = await userApi.getLoggedInUser();
			commit('setUserData', userData);
		},
		/** Fetches the feature set and stores it */
		async fetchFeatureSet({commit}) {
			const systemApi = ClientManager.getInstance().squeeze.system;
			const featureSet = await systemApi.featureSet();
			commit('setFeatureSet', featureSet);
		},
		/** Fetches the migrations and stores it */
		async fetchMigrations({commit}) {
			try {
				const systemApi = ClientManager.getInstance().squeeze.system;
				const allMigrations = await systemApi.getMigrations();
				const errorMigrations =  allMigrations.filter((migration: MigrationStatus) => migration.up === false);
				commit('setMigrations', errorMigrations);
			} catch(error: unknown) {
				return;
			}
		},
		/** Fetches the user settings and stores it */
		async fetchUserSettings({commit}) {
			const userApi = ClientManager.getInstance().squeeze.user;
			// check if user settings exist in local storage
			const localStorageUserSettings = localStorage.getItem('userSettings');
			if (localStorageUserSettings) {
				try{
					const userSettings = JSON.parse(localStorageUserSettings);
					// check if table settings undefined
					if (!userSettings.tableSettings) {
						userSettings.tableSettings = squeezeStore.state.userSettings.tableSettings;
					}

					// To save it, the value has to be a string
					if (userSettings.viewSettings && typeof userSettings.viewSettings !== "string") {
						userSettings.viewSettings = JSON.stringify(userSettings.viewSettings);
					}

					userApi.putAllUserProperties(userSettings)
						.then(() => {
							localStorage.removeItem('userSettings');
							userSettings.viewSettings = JSON.parse(userSettings.viewSettings);
							commit('setUserSettings', userSettings);
						}).catch(response => response.json().then((err: { message: string; statusCode: number }) => {
							// If there is an error, always remove userSettings from localstorage to prevent errors, show no errors, because they don't matter here
							localStorage.removeItem('userSettings');
						}))
				}
				catch(e: unknown) {
					// If there is an error, always remove userSettings from localstorage to prevent errors, show no errors, because they don't matter here
					localStorage.removeItem('userSettings');
				}
			} else {
				// get all user settings
				try {
					const allUserSettings = await userApi.getAllUserProperties();
					if (allUserSettings) {
						// check if view settings empty
						if (!allUserSettings.viewSettings) {
							allUserSettings.viewSettings = JSON.stringify(squeezeStore.state.userSettings.viewSettings);
						}

						// set current user settings
						const currentUserSettings: UserSettings = {
							viewSettings: JSON.parse(allUserSettings.viewSettings as string),
							tableSettings: allUserSettings.tableSettings as string,
						};

						localStorage.setItem('fontSize', JSON.stringify(currentUserSettings.viewSettings.fontSize));
						commit('setUserSettings', currentUserSettings);
					}
				} catch (error: unknown) {
					return;
				}
			}
		},
		/** Fetches the System Information and stores it */
		async fetchSystemInformation({commit}) {
			const systemApi = ClientManager.getInstance().squeeze.system;
			const systemInformation = await systemApi.getSystemInformation();
			commit('setSystemInformation', systemInformation);
		},
	},
	modules: {
	},
})

// define your own `useStore` composition function
export function useSqueezeStore() {
	return useStore(squeezeStoreKey)
}

export default useSqueezeStore
