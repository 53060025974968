<template>
	<div class="container p-p-2">
		<div class="content">
			<RecordList
				:loading="loading"
				:pagination="paginationOption"
				:totalSize="paginationOption.total"
				:records="records"
				:searchFields="searchFields"
				:storeId="storeId"
				:filterOptions="filters"
				:firstEntry="(paginationOption.page * paginationOption.pageSize)"
				:searchMaskId="searchMaskId"
				:searchQuery="searchQuery"
				:sortField="sortField"
				:sortOrder="sortOrder"
				v-model:fullText="fullText"
				@onReload="onReload"
				@onRowSelect="onRowSelect"
				@onPage="onPage"
				@onSort="onSort"
				@onFilter="onFilter"
				@clearSearch="clearSearch"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, inject, onMounted, PropType, reactive, ref} from 'vue';
import {
	DocumentSearchRequestDto,
	FieldValueFilterDto,
	PaginationDto,
	WorkflowContextFilterDto
} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import RecordList from "@/apps/freeze/components/RecordList.vue";
import {FreezeSearchMaskField} from "@/apis/freeze/Types";
import {DocumentFilterObject, Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import router from "@/router";
import {EasClientKey, FreezeClientKey} from "@/apps/freeze/DI";
import {TableSettings} from "@/util/TableSettings";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {useRoute} from "vue-router";
import {ClientManager} from "@/singletons/ClientManager";
import useProductStore from "@/store/product-store";

export default defineComponent({
	name: 'StoreListView',
	components: {
		RecordList,
	},
	props: {
		storeId: {
			type: String,
			default: '',
		},
		searchMaskId: String,
		searchRequest: {
			type: Object as PropType<DocumentSearchRequestDto>,
			default: {},
		},
		tableSortStart: {
			type: Array as PropType<any[]>,
			default: [],
		},
		pagination: {
			type: Object as PropType<PaginationDto>,
		},
	},

	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Product Store */
		const productStore = useProductStore();

		/** Vuex Squeeze Store */
		const squeezeStore = useSqueezeStore() || undefined;

		/** User API endpoint (Squeeze) */
		const userApi = ClientManager.getInstance().squeeze.user || undefined;

		const freezeApi = inject(FreezeClientKey)!
		const easApi = inject(EasClientKey)!

		/** list of all records */
		const records = ref<any[]>([]);

		/** Is the table currently loading */
		const loading = ref(false);

		/** Empty pagination object & Info / Options of pagination  */
		const paginationOption = reactive<PaginationDto>({pageSize: 25, page: 0, total: 0});

		/** URL to next records */
		const nextPageUrl = ref("");

		/** List of all Document-Class-Fields */
		const searchFields = ref<FreezeSearchMaskField[]>([]);

		const sortField = ref<string|null>(null);
		const sortOrder = ref<string|null>(null);

		/** Filters of Documentlist */
		const filters = ref<DocumentFilterObject>({
			"ID": {value: null, matchMode: FilterMatchMode.EQUALS},
		});

		const fullText = ref("");

		const searchQuery = ref<string[]>([]);

		/**
		 * Get the current search mask fields
		 */
		const getSearchMaskFields = async () => {
			try {
				const data = await freezeApi.getAllSearchMasks();
				const searchMask = data.find(searchMask => searchMask.name === props.searchMaskId);
				if (searchMask) {
					searchFields.value = searchMask.fields;

					searchFields.value.forEach(field => {
						filters.value['' + field.name] = {value: null, matchMode: "raw"};
					});
				}
			}catch (e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/**
		 * Sets the filters for fields
		 */
		const setFieldFilters = () => {
			const searchRequestFilters: {[type: string]: FieldValueFilterDto[] | WorkflowContextFilterDto[] | undefined} = {
				"fields": props.searchRequest.fieldFilters,
				"wf": props.searchRequest.workflowContextFilters,
			};

			for(const filterType in searchRequestFilters) {
				const isField = filterType === "fields";
				const filtersCheck = searchRequestFilters[filterType];
				if(filtersCheck) {
					filtersCheck.forEach((filter: any) => {
						const id = isField ? '' + filter.fieldId : filter.fieldName;
						const listFilter = {value: filter.searchValue, matchMode: FilterMatchMode.CONTAINS};

						switch(filter.comp) {
						case "eq": listFilter.matchMode = FilterMatchMode.EQUALS; break;
						case "bw": listFilter.matchMode = FilterMatchMode.STARTS_WITH; break;
						case "ew": listFilter.matchMode = FilterMatchMode.ENDS_WITH; break;
						default: break;
						}

						filters.value[id as string] = listFilter;
					});
				}
			}
		}

		/**
		 * Contains all valid operators for EAS
		 * @param searchValue
		 */
		const containsValidOperator = (searchValue: string) => {
			if (searchValue.includes("'") || searchValue.includes("*"))
			{
				return true;
			}

			return false;
		}

		/**
		 * Get all records
		 * @param search
		 */
		const getRecords = async (search: DocumentSearchRequestDto) => {
			search.fieldFilters?.forEach(filter => {
				// If the field is of type "amount"/"date" or the search value already includes ' = or *, nothing should be added
				if (!containsValidOperator(filter.searchValue)) {
					filter.searchValue = "*" + filter.searchValue + "*";
				}
			})

			loading.value = true;

			let query = search.fieldFilters?.map(field => {
				return (field.fieldId + '') + ":" + field.searchValue
			})

			if (!query) {
				query = [];
			}

			const queryFulltext = search.fulltextFilters?.map(field => {
				return field.searchValue
			})

			if (queryFulltext) {
				query = query.concat(queryFulltext);
			}

			if (query.length === 0) {
				query.push("*");
			}

			searchQuery.value = query;

			try {
				const data = await easApi.getRecords(props.storeId!, query, paginationOption.page!, paginationOption.pageSize!, sortField.value, sortOrder.value);
				// paginationOption.page = data.startIndex;
				paginationOption.total = data.totalHits;
				records.value = data.result;
				nextPageUrl.value = data.nextPageUrl;
			} catch (e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}

			loading.value = false;
			setFieldFilters();
		}


		/**
		 * Triggered on Sort
		 * @param sortFieldSet
		 * @param sortOrderSet
		 */
		const onSort = async (sortFieldSet: string|null, sortOrderSet: number|null) => {
			paginationOption.page = 0;
			sortField.value = sortFieldSet;

			// Special for headerfields
			if (sortField.value && sortField.value.includes('headerFields.')) {
				sortField.value = sortField.value.replace('headerFields.', "");
			}

			if (sortOrderSet === -1) {
				sortOrder.value = "desc";
			} else if (sortOrderSet === 1) {
				sortOrder.value = "asc";
			} else {
				sortOrder.value = null;
			}

			await getRecords(props.searchRequest);
		}

		/**
		 * Triggered on change page of table
		 * @param event
		 */
		const onPage = (event: any) => {
			paginationOption.page = event.page;
			paginationOption.pageSize = event.rows;
			getRecords(props.searchRequest);

			if (productStore.state.squeeze) {
				TableSettings.saveTableListPagination(t, toast, squeezeStore, userApi, route.name, event.rows);
			}
		}

		/**
		 * Checks the fulltext and sets it to the field
		 * @param search
		 */
		const checkFullText = (search: DocumentSearchRequestDto) => {
			if (search && search.fulltextFilters && search.fulltextFilters[0]) {
				const fullTextFilter = search.fulltextFilters[0];
				if (fullTextFilter && fullTextFilter.searchValue) {
					fullText.value = fullTextFilter.searchValue;
				}
				else {
					fullText.value = "";
				}
			}else {
				fullText.value = "";
			}
		}

		/**
		 * Set the search request
		 * @param tableFilters
		 */
		const setSearchRequest = (tableFilters: DocumentFilterObject) => {
			const setSearchRequest: DocumentSearchRequestDto = {
				fulltextFilters: [],
				fieldFilters: [],
				workflowContextFilters: [],
			}

			if (fullText.value) {
				paginationOption.page = 0;
				if (!containsValidOperator(fullText.value)) {
					fullText.value = "*" + fullText.value + "*";
				}

				setSearchRequest.fulltextFilters!.push({
					searchValue: fullText.value,
					comp: 'raw',
				})
			}

			filters.value = tableFilters;
			const fieldFilters: any[] = [];
			const workflowContextFilter: WorkflowContextFilterDto[] = [];

			for(const id in tableFilters) {
				const filterId = id;
				const isField = true;

				const filter: Filter = tableFilters[id];
				if(filter && filter.value != null && filter.value != "") {
					paginationOption.page = 0;
					if(!isField) {
						// WF CONTEXT
						workflowContextFilter.push({
							fieldName: id,
							searchValue: filter.value.toLowerCase(),
							comp: "raw",
							fieldType: "text",
						});
					} else {
						// FIELD
						const column = searchFields.value.find(field => {
							return field.name === filterId;
						});

						if (column) {
							fieldFilters.push({
								fieldId: filterId,
								searchValue: filter.value as string,
								comp: "raw",
								fieldType: "text",
							})
						}
					}
				}
			}

			setSearchRequest.fieldFilters = fieldFilters;
			setSearchRequest.workflowContextFilters = workflowContextFilter;
			checkFullText(setSearchRequest);

			return setSearchRequest;
		}

		/**
		 * Triggered on Filter on the table
		 * @param tableFilters
		 */
		const onFilter = async (tableFilters: DocumentFilterObject) => {
			const searchRequest = setSearchRequest(tableFilters);

			await router.replace({ name: 'FreezeRecords', params: {
				storeId: "" + props.storeId,
				searchMaskId: "" + props.searchMaskId,
				searchRequest: JSON.stringify(searchRequest),
				tableSortStart: JSON.stringify(props.tableSortStart),
				pagination: JSON.stringify(props.pagination),
			}});

			await getRecords(props.searchRequest);
		}

		const onReload = async () => {
			await onFilter(filters.value);
		}

		/** Triggered when a row is selected in list and opens a record
		 * @param record
		 */
		const onRowSelect = (record: any) => {
			const searchRequest = setSearchRequest(filters.value);

			router.push({ name: 'FreezeRecord', params: {
				recordId: record.id,
				storeId: props.storeId,
				searchMaskId: "" + props.searchMaskId,
				searchRequest: JSON.stringify(searchRequest),
				tableSortStart: JSON.stringify(props.tableSortStart),
				pagination: JSON.stringify(paginationOption),
			}, query: {
				hitList: props.searchMaskId,
			}});
		}

		/** Clears all searches */
		const clearSearch = async () => {
			searchFields.value.forEach(field => {
				filters.value['' + field.name] = {value: null, matchMode: "raw"};
			});

			fullText.value = "";
			paginationOption.page = 0;
			await onFilter(filters.value);
		}

		onMounted(() => {
			getSearchMaskFields();
			checkFullText(props.searchRequest);
			getRecords(props.searchRequest);


			if (productStore.state.squeeze) {
				paginationOption.pageSize = TableSettings.getTableListPagination(squeezeStore, route.name);
			} else {
				Object.assign(paginationOption, props.pagination);
			}
		});

		return {
			paginationOption,
			loading,
			records,
			searchFields,
			filters,
			fullText,
			searchQuery,
			sortField,
			sortOrder,
			onReload,
			onFilter,
			onSort,
			onRowSelect,
			onPage,
			clearSearch,
			getRecords}
	},
});

</script>

<style lang="scss" scoped>

.container {
	display: flex;
	flex-flow: column nowrap;
	height: 100vh;
}

.header {
	height: 100px;
}

.content {
	height: calc(100vh - 15px);

	@media only screen and (max-width: 767px) {
		height: 100vh;
	}
}

</style>

