<template>
	<div class="p-fluid p-formgrid p-grid">
		<template v-for="(rowContent, index) in layoutDetails" :key="index">
			<div class="p-grid p-mr-0 p-ml-0 p-mt-0 p-p-0 p-col-12" :class="'row-content-' + index">
				<template v-for="(layout, rowIndex) in rowContent" :key="rowIndex">

					<!-- DIVIDER / HR -->
					<div v-if="layout.type === 'hr'" :class="'p-col-' + layout.width" style="align-self: center;">
						<Divider style="border: 1px var(--primary-color) solid;" />
					</div>

					<template v-for="documentClassField in documentClassFields" :key="documentClassField.id">
						<!-- LABEL (FIELD) -->
						<!--div class="p-col-3" v-show="!documentClassField.hidden">
							<p style="font-size: 1rem;">{{ documentClassField.description }}</p>
						</div-->

						<!-- FIELD -->
						<div
							v-if="layout.type === 'field' && layout.id === documentClassField.id
							|| !documentClassField.colSize && !layout.id.toString().includes(documentClassField.id.toString()) && layoutDetails.length -1 === index && rowContent.length -1 === rowIndex"
							:class="[
								'p-field',
								!documentClassField.colSize ? 'p-col-12' : documentClassField.colSize,
								layout.offsetSize && layout.offset !== 0 && documentClassField.colSize ? 'p-offset-' + layout.offsetSize : '',
								rowContent.length > 1 ? layout.offsetSize : '',
								{'low-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === true},
							]"
							v-show="!documentFields[getDocumentFieldIndex(documentClassField.name)].hidden">
							<div class="p-input-filled">
								<!-- TEXT FIELD -->
								<div style="margin-bottom: 0.25rem;">
									<label class="p-mb-3" :for="documentClassField.name">
										{{ documentClassField.description }}
										<Badge v-if="documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1"
											:value="documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length"
											v-tooltip="$t('Squeeze.Validation.General.CountAlternatives', { count: documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length })"
											severity="warning"></Badge>
									</label>
								</div>
									<InputText
										v-if="(documentClassField.dataType.toLowerCase() === 'text' || documentClassField.dataType.toLowerCase() === 'date')
											&& (!documentClassField.lookup || documentClassField.lookup.active === false)
											&& documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length <= 1
											|| (isReadOnlyMode && documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1)
											|| (isReadOnlyMode && documentClassField.dataType.toLowerCase() === 'text' && documentClassField.lookup && documentClassField.lookup.active === true)
											|| (documentClassField.readonly && documentClassField.dataType.toLowerCase() !== 'amount')"
										:id="documentClassField.name"
										:aria-describedby="documentClassField.name + '-help'"
										type="text"
										:class="[
											{'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
											{'p-invalid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
											{'forceapproval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
											{'valid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
											{'readonly': documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode},
										]"
										v-model="documentFields[getDocumentFieldIndex(documentClassField.name)].value.value"
										@focus="onFocusField($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@change="emitValidationRequest($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.enter="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.tab="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										:ref="el => { setFieldReference(el, documentFields[getDocumentFieldIndex(documentClassField.name)]) }"
										:readonly="documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode"
										v-tooltip.bottom="(documentFields[getDocumentFieldIndex(documentClassField.name)].value.state && documentFields[getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : documentFields[getDocumentFieldIndex(documentClassField.name)].value.errorText"
									/>
									<!-- LOOKUP-Field -->
									<AutoComplete
										v-if="(!isReadOnlyMode && documentClassField.dataType.toLowerCase() === 'text' && documentClassField.lookup && documentClassField.lookup.active === true && !documentClassField.readonly)
											|| (!isReadOnlyMode && documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1 && !documentClassField.readonly)"
										:id="documentClassField.name"
										:aria-describedby="documentClassField.name + '-help'"
										type="text"
										:class="[
											{'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
											{'p-invalid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
											{'forceapproval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
											{'valid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
											{'readonly': documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode},
											{'amount-field': documentClassField.dataType.toLowerCase() === 'amount'},
										]"
										:inputClass="[
											{'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
											{'p-invalid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
											{'forceapproval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
											{'valid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
											{'readonly': documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode},
											{'amount-field': documentClassField.dataType.toLowerCase() === 'amount'},
										]"
										v-model="documentFields[getDocumentFieldIndex(documentClassField.name)].value.value"
										@focus="onFocusField($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@blur="emitValidationRequest($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.enter="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.tab="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										:ref="el => { setFieldReference(el, documentFields[getDocumentFieldIndex(documentClassField.name)]) }"
										:readonly="documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode"
										:minLength="documentClassField.lookup.minInputLength"
										:suggestions="filteredValues[documentClassField.name]"
										field="label"
										@keydown="onKeydown($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@click="onClickAutocomplete($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@complete="searchAutocomplete($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@item-select="onItemSelect($event, documentClassField)"
										@item-hover="onHoverItemAutocomplete($event, documentClassField)"
										:forceSelection="(documentClassField.lookup && documentClassField.lookup.active === true) && !documentClassField.lookup.allowCustomValues"
										:autoHighlight="true"
										:dropdownMode="'current'"
										v-tooltip.bottom="(documentFields[getDocumentFieldIndex(documentClassField.name)].value.state && documentFields[getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : documentFields[getDocumentFieldIndex(documentClassField.name)].value.errorText"
										:dropdown="!isReadOnlyMode && (documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length > 1  || documentFields[getDocumentFieldIndex(documentClassField.name)].lookup.minInputLength === 0)"
									>
										<template #item="slotProps">
											<div class="p-ml-2">{{slotProps.item.label}}</div>
										</template>
									</AutoComplete>
									<!-- DATE FIELD -->
									<!-- FIXME: The date handling of PrimeVue / Vue does currently not work. The wrong date format is sent to the backend which can't handle it and responds with an empty string -->
									<!--					<Calendar-->
									<!--						v-if="documentClassField.dataType.toLowerCase() === 'date'"-->
									<!--						:id="documentClassField.name"-->
									<!--						v-model="documentFields[getDocumentFieldIndex(documentClassField.name)].value.value"-->
									<!--						dateFormat="dd.mm.yy"-->
									<!--						:class="{-->
									<!--								'p-invalid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR',-->
									<!--								'forceapproval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL',-->
									<!--								'valid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'OK',-->
									<!--							}"-->
									<!--						@focus="onFocusField(documentClassField)"-->
									<!--						@blur="emitValidationRequest(documentFields[getDocumentFieldIndex(documentClassField.name)])"-->
									<!--						@keydown.enter="emitValidationRequest(documentFields[getDocumentFieldIndex(documentClassField.name)])"-->
									<!--						:disabled="documentClassField.readonly"-->
									<!--					/>-->
									<!-- AMOUNT FIELD -->
									<InputText
										v-if="documentClassField.dataType.toLowerCase() === 'amount' && documentFields[getDocumentFieldIndex(documentClassField.name)].alternatives.length <= 1"
										:id="documentClassField.name"
										v-model="documentFields[getDocumentFieldIndex(documentClassField.name)].value.value"
										:class="[
											{'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === false},
											{'p-invalid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR'},
											{'forceapproval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'},
											{'valid': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'OK'},
											{'readonly': documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode},
											'amount-field',
										]"
										@focus="onFocusField($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@change="emitValidationRequest($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.enter="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										@keydown.tab="onEnter($event, documentFields[getDocumentFieldIndex(documentClassField.name)])"
										:ref="el => { setFieldReference(el, documentFields[getDocumentFieldIndex(documentClassField.name)]) }"
										:readonly="documentFields[getDocumentFieldIndex(documentClassField.name)].readonly || isReadOnlyMode"
										v-tooltip.bottom="(documentFields[getDocumentFieldIndex(documentClassField.name)].value.state && documentFields[getDocumentFieldIndex(documentClassField.name)].value.state.toLowerCase() !== 'error') ? '' : documentFields[getDocumentFieldIndex(documentClassField.name)].value.errorText"
									/>
									<!--small
										v-if="documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR' || documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL'"
										:id="documentFields[getDocumentFieldIndex(documentClassField.name)].name + '-help'"
										:class="{
											'p-error': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'ERROR',
											'field-approval': documentFields[getDocumentFieldIndex(documentClassField.name)].value.state === 'FORCEAPPROVAL',
										}">{{ documentFields[getDocumentFieldIndex(documentClassField.name)].value.errorText }}
									</small-->
							</div>
						</div>
					</template>
				</template>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
/* eslint max-lines: off */
import {Options, Vue} from 'vue-class-component';
import {DocumentField, FieldLookupFilter} from "@dex/squeeze-client-ts";
import InputText from 'primevue/inputtext';
import InputNumber from '@/components/DexInputNumber.vue';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import ScrollPanel from 'primevue/scrollpanel';
//import AutoComplete from 'primevue/autocomplete';
import AutoComplete from '@/components/DexAutocomplete.vue';
import {ClientManager} from "@/singletons/ClientManager";
import {AutoCompleteOnCompleteEvent} from "@/shims-prime-vue";
import Badge from 'primevue/badge';
import Tooltip from "primevue/tooltip";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {LookupDefinition, ValidationFieldDto} from "@dex/squeeze-client-ts";
import Divider from 'primevue/divider';

interface LookupDefinitionWithFilters extends LookupDefinition {
	lookupFieldFilters: FieldLookupFilter[];
}

interface DocumentFieldWithLookupFilter extends DocumentField{
	lookup?: LookupDefinitionWithFilters;
}

interface UiHeadFields {
	name: string;
	field: DocumentField;
	element: any;
}

@Options({
	name: "ValidationFieldSet",
	components: {
		InputText,
		InputNumber,
		Textarea,
		Calendar,
		ScrollPanel,
		AutoComplete,
		Badge,
		Divider,
	},
	props: {
		documentClassFields: Array,
		documentFields: Array,
		id: Number,
		name: String,
		description: String,
		layoutDetails: Array,
		isReadOnlyMode: Boolean,
		documentId: Number,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		'onFocusField',
		'onBlur',
		'allHeadRefFields',
		'enterDocumentGroup',
		'onHoverItemAutocomplete',
	],
})
export default class ValidationFieldSet extends Vue {

	/** List of all DocumentFields of document class */
	documentClassFields!: DocumentField[];

	/** List of all DocumentFields of document */
	documentFields!: DocumentField[];

	/** Field group id */
	id!: number;

	/** Document id */
	documentId!: number;

	/** Technical field group name */
	name!: string;

	/** Field group description */
	description!: string;

	/** Is the current mode of component readonly? */
	isReadOnlyMode!: boolean;

	/** Object with the current filtered values */
	filteredValues?: any = []

	/** Object with the current alternative values */
	alternativeValues?: any = []

	/** Service for getting the master-data-lookups */
	masterDataService = ClientManager.getInstance().squeeze.masterData;

	/** Service for getting the master-data-lookups */
	documentService = ClientManager.getInstance().squeeze.document;

	/** Array with Fields  */
	headRefField: UiHeadFields[] = [];

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Field Layout Details */
	layoutDetails!: string;

	/** Emits the onFocusField-Event with the current-field  */
	onFocusField(event: FocusEvent, field: DocumentField) {
		if (event && event.target) {
			(event.target as HTMLInputElement).select();
		}

		this.$emit("onFocusField", field);
	}

	/** Emits the onFocusField-Event with the current-field  */
	onHoverItemAutocomplete(event: any, field: DocumentField) {
		// Only trigger event if there is something in the values to emit
		if (event.value && event.value.completeValue) {
			this.$emit("onHoverItemAutocomplete", event.value.completeValue);
		}
	}

	/** Emits the onValidationRequest-Event with the current-field */
	emitValidationRequest(event: any, field: DocumentField) {
		// In AutoComplete box if entry in popup list is clicked
		// first the input's blur event is triggered.
		// But in this case no further action (Validation) should be taken
		// but only after real blur (leave) of input.
		if (event && event.target) {
			const node = event.target as Node;
			if (node.parentElement && node.parentElement.getAttribute('aria-expanded') === 'true') {
				return;
			}
		}

		if(field.forceValidation && field.state !== "OK") {
			field.state = "OK";
		}

		// Check if the type is 'button', then return (prevent duplicate validation request)
		if (event.relatedTarget && event.relatedTarget.type && event.relatedTarget.type === 'button') {
			return;
		}

		this.$emit("onBlur", field);
	}

	/** Return index of document field */
	getDocumentFieldIndex(name: string) {
		return this.documentFields.findIndex(field => field.name == name);
	}

	/**
	 * Event that is triggered when an item is selected on Autocomplete
	 * @param event Event of Autocomplete
	 * @param documentClassField Current documentClassField
	 */
	onItemSelect(event: any, documentClassField: DocumentField) {
		// At this point we used the value (instead of the label), because we want to write the ID in the lookup field.
		this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.value = event.value.value;

		if (event.value.completeValue) {
			this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.boundingBox = event.value.completeValue.boundingBox;
		} else {
			this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.boundingBox = undefined;
		}
	}

	/**
	 * Gets the value from a field by field id
	 * @param fieldId
	 */
	getValueFromField(fieldId: number) {
		const field = this.documentFields.find(field => field.id === fieldId);

		if (field && field.value) {
			return field.value!.value;
		}

		// If no value can be found, search for something illogical that never can be found
		return "FieldNotFound";
	}

	/**
	 * Event that is triggered when users make autocomplete-inputs
	 * @param event Event of Autocomplete
	 * @param field Current documentClassField
	 */
	searchAutocomplete(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.setAutocompleteValues(event, field);
	}

	/**
	 * Emits on field confirm via enter with the current-field
	 * @param event
	 * @param field
	 */
	onEnter(event: KeyboardEvent, field: DocumentField) {
		event.preventDefault();

		if(field.forceValidation && field.state !== "OK") {
			field.state = "OK";
			this.$emit("onBlur", field);
		}

		const allVisibleFields = this.headRefField.filter(field => field.field.hidden === false);
		let index = allVisibleFields.findIndex(refField => refField.field.name === field.name);
		if (event.shiftKey) {
			index -= 1;
		} else {
			index += 1;
		}

		if (allVisibleFields[index]) {
			allVisibleFields[index].element.$el.focus();
			if (allVisibleFields[index].field.lookup?.active === true && !this.isReadOnlyMode ||
				(allVisibleFields[index].field.alternatives!.length > 0 && allVisibleFields[index].element.$el.firstElementChild && allVisibleFields[index].element.$el.firstElementChild != null)) {
				allVisibleFields[index].element.$el.firstElementChild.focus();
			}
		} else {
			// If a field is the last one of a group, also emit onblur, so the validation request will be triggered
			if (event.shiftKey) {
				this.$emit("enterDocumentGroup", this.id, false);
			} else {
				this.$emit("enterDocumentGroup", this.id, true);
			}
			return;
		}

		if (allVisibleFields.length === index) {
			this.$emit("enterDocumentGroup", this.id, true);
		} else if (index === -1) {
			this.$emit("enterDocumentGroup", this.id, false);
		}
	}

	/**
	 * Set field reference
	 * @param element
	 * @param field
	 */
	setFieldReference(element: Element, field: DocumentField) {
		const refField = this.headRefField.find(refField => refField.name === field.name);
		if (field.readonly === true) {
			return
		}

		if (!refField) {
			const newField: UiHeadFields = {
				name: field.name || "Field name",
				field: field,
				element: element,
			};

			this.headRefField.push(newField)
		}
	}

	/**
	 * Sets the Value for the autocomplete/lookup
	 * @param event
	 * @param field
	 */
	async setAutocompleteValues(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.alternativeValues[field.name!] = [];
		if (!field.lookup!.active && field.alternatives!.length > 0) {
			// show all alternatives in dropdown
			const alternatives = field.alternatives!
				.map(alternative => {
					const label = alternative.value;
					const value = alternative.value;
					const completeValue = alternative;
					return {value, label, completeValue};
				})

			this.filteredValues[field.name!] = alternatives;

			// Add current entry if text is written to the field
			if (event.query) {
				const currentValue = {
					value: event.query,
					label: event.query,
					completeValue: {
						boundingBox: {
							x1: 0,
							y1: 0,
							page: 0,
							x0: 0,
							y0: 0,
						},
					},
				}
				this.filteredValues[field.name!].unshift(currentValue);
			}
		} else if (field.lookup!.active) {
			// Otherwise the reference to this.documentfields is referenced and will be changed
			const documentFieldClone = JSON.parse(JSON.stringify(this.documentFields)) as ValidationFieldDto[];
			if (!event.query) {
				event.query = "";
			}

			// Sometimes value is null when the field is cleared. If it is, it's an empty field
			const autocompleteField = documentFieldClone.find((documentField: DocumentField) => documentField.name === field.name);
			if (autocompleteField) {
				if(!autocompleteField.value!.value) {
					autocompleteField.value!.value = "";
				}
			}

			const rows = await this.documentService.getDocumentFieldLookupValues(this.documentId, Number(field.id), {
				documentFields: documentFieldClone,
				fieldSearchValue: event.query,
			}) as any;
			const resultColumns = field.lookup?.resultValueColumnIds;

			const alternatives = rows
				.map((row: any) => {
					const completeValue = row;
					const value = row.resultValue;
					const label = resultColumns?.map(col => row.displayColumnResults[col]).join(" | "); // Map result columns to a single string to be displayed
					return {value, label, completeValue};
				})

			this.filteredValues[field.name!] = alternatives;

			// Add current entry if text is written to the field
			if (event.query && field.lookup?.allowCustomValues) {
				const currentValue = {
					value: event.query,
					label: event.query,
					completeValue: {
						boundingBox: {
							x1: 0,
							y1: 0,
							page: 0,
							x0: 0,
							y0: 0,
						},
					},
				}
				this.filteredValues[field.name!].unshift(currentValue);
			}
		}
	}

	/**
	 * Triggered when a Dropdown is clicked. Currently this triggered by clicking the Autocomplete-Field.
	 * @param event
	 * @param field
	 */
	onClickDropdown(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.setAutocompleteValues(event, field);
	}

	/**
	 * Event that is triggered on click on autocomplete-fields
	 * @param event
	 * @param field Current documentClassField
	 */
	onClickAutocomplete(event: unknown, field: DocumentField) {
		// Trigger onDropDownClick-Event, so the alternatives are shown when there are alternatives. Otherwise do nothing
		if (field.alternatives!.length > 1 || (field.lookup?.active === true && field.lookup.minInputLength === 0)) {
			const index = this.headRefField.findIndex(refField => refField.name === field.name);
			this.headRefField[index].element.onDropdownClick(event, field);
		}
	}

	/**
	 * Check the length of the description
	 * @param description
	 */
	checkDescriptionLength(description: string) {
		if (description.length > 15 && !description.includes('-') && !description.includes(' ')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Triggered on keydown
	 * @param {KeyboardEvent} event
	 * @param {DocumentField} field Current documentClassField
	 */
	onKeydown(event: KeyboardEvent, field: DocumentField) {
		// if key ctrl and arrow down pressed, then open the autocomplete popup
		if (event.code === 'ArrowDown' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			this.onClickAutocomplete(event, field);
		}
	}

	/**
	 * Triggers the check of values on autocomplete-fields. If the value is invalid, the value will be cleared.
	 * This function is needed for values that are inserted to fields via Squeeze-Viewer
	 * @param event
	 * @param field
	 * @param fieldValue
	 */
	checkAutocompleteValues = async (event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter, fieldValue: any = '') => {
		if (!fieldValue) {
			return;
		}

		await this.setAutocompleteValues(event, field);

		if (this.filteredValues[field.name as any]) {
			const index = this.headRefField.findIndex(refField => refField.name === field.name);
			if(!this.filteredValues[field.name as any].find((filteredValue: any) => filteredValue.value === fieldValue)) {
				this.documentFields[this.getDocumentFieldIndex(field.name!)].value!.value = "";
			} else {
				this.headRefField[index].element.showOverlay();
			}
		}
	}

	mounted() {
		this.$emit("allHeadRefFields", this.headRefField);
	}

}
</script>

<style lang="scss" scoped>
.readonly {
	font-weight: bold;
	color: #2b2b2b;
	opacity: 0.5;
}

.validation-field {
	margin-top: 0.5rem;
}

.p-field.low-field {
	margin-bottom: 0.5rem;
}

.low-field ::v-deep(.p-autocomplete .p-button) {
	padding: 0.5rem;
	width: 2.5rem;
}

.validation-field ::v-deep(.p-fieldset-legend a) {
	padding: 1rem !important;
}

.validation-field ::v-deep(.p-autocomplete-input),
.validation-field ::v-deep(.p-button), {
	margin-top: 0.25rem;
}

.low-fieldset ::v-deep(.p-fieldset-content) {
	padding: 0 1rem 0 1rem !important;
}

input.p-inputtext.p-component.p-filled.amount-field{
	text-align: right;
}

::v-deep(input.p-autocomplete-input.p-inputtext.p-component.amount-field) {
	text-align: right;
}

.low-field > .p-input-filled > .p-inputtext {
	padding: 0.5rem 0.5rem !important;
}

.low-field ::v-deep(.p-autocomplete-input.p-inputtext) {
	padding: 0.5rem 0.5rem !important;
}

::v-deep(.p-badge) {
	min-width: 1rem;
	width: max-content;
	height: 1rem;
	line-height: 1rem;
	margin-left: 0.25rem;
	border-radius: 0;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown) {
	background: #4894ac4d;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown span.p-button-icon) {
	color: var(--primary-color);
}

::v-deep(.p-input-filled .p-inputtext.forceapproval.p-component) {
	background-color: #4894ac4d;
}

::v-deep(.p-input-filled .p-inputtext.p-invalid.p-component) {
	background-color: #ffcdd254;
}

</style>
